/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import Logo from '../../assets/images/aery_logo_icon.png'
import SidebarItem from './sidebar-item.component'
import {
  SidebarItemStyles,
  SidebarContainer,
  SidebarWrapper,
  SidebarStyles,
  SidebarFixer,
  HomeLink,
} from './sidebar.styles'
import Icon from '../../layout/icons/icons.styles'
import { Button } from '../../layout'
import { IUser } from '../../interfaces/user/user.interface'
import { BRAND_IMAGE } from '../../config/layout.config'
import DarkModeContext from '../../contexts/darkmode.context'
import { AUTH_COOKIE } from '../../config/variables.config'

interface ISidebar {
  setSideBar: (val: boolean) => void
  isOpen: boolean
  history?: any
  user: IUser
}

const Sidebar = (props: ISidebar) => {
  const { isOpen, setSideBar, user, history = { location: { pathname: '' } } } = props
  const { location: { pathname = '' } = { pathname: '' } } = history
  const isActiveSettings = pathname === '/settings' || pathname === '/dictionary' || pathname === '/routing'

  const [cookies, setCookie, removeCookie] = useCookies([AUTH_COOKIE, 'sidebarOpen'])
  const [settingsToggle, setSettingsToggle] = useState(isActiveSettings)
  const [userToggle, setUserToggle] = useState(false)

  const darkModeCtx = useContext(DarkModeContext)

  const getDomain = () => {
    // if localhost -> localhost
    // if sub.sub.sub.domain.com -> .domain.com
    const { host } = window.location
    if (host.includes('localhost')) return `localhost`
    return `.${host.split('.').slice(-2).join('.')}`
  }

  const onLogOut = () => {
    removeCookie(AUTH_COOKIE, { path: '/', domain: getDomain() })
    window.location.href = '/'
  }

  const onSidebarFix = (value: boolean) => {
    setCookie('sidebarOpen', `${value}`, { path: '/' })
  }

  useEffect(() => {
    // eslint-disable-next-line no-unneeded-ternary
    setSideBar(cookies.sidebarOpen === 'true' ? true : false)
  }, [])

  if (!user) return null
  const { firstname, lastname } = user

  return (
    <SidebarStyles fixed={isOpen} backgroundImage={BRAND_IMAGE} {...darkModeCtx}>
      <SidebarWrapper>
        <SidebarContainer bottomLine>
          <SidebarItemStyles header pills={false}>
            <img src={Logo} alt="" />
            <HomeLink to="/" visible={isOpen}>
              Aery Labs
            </HomeLink>

            <SidebarFixer fixed={isOpen}>
              <Button
                fixer
                onClick={() => {
                  setSideBar(!isOpen)
                  onSidebarFix(!isOpen)
                }}
              >
                <Icon>{isOpen ? 'menu_open' : 'menu'}</Icon>
              </Button>
            </SidebarFixer>
          </SidebarItemStyles>
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            onClick={() => setUserToggle(!userToggle)}
            isActive={pathname === '/profile'}
            title={`${firstname} ${lastname}`}
            open={userToggle}
            isFixed={isOpen}
            type="parent"
            layout="pils"
            icon="person"
          />
          <SidebarItem
            isActive={pathname === '#'}
            onClick={onLogOut}
            open={userToggle}
            isFixed={isOpen}
            title="Log out"
            layout="pils"
            type="child"
            addFunction
            icon="lock"
            url="#"
          />
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            isActive={pathname === '/pages'}
            isFixed={isOpen}
            icon="web_asset"
            title="Pages"
            layout="pils"
            url="/pages"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/menus'}
            isFixed={isOpen}
            icon="list"
            title="Menus"
            layout="pils"
            url="/menus"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/media'}
            isFixed={isOpen}
            url="/media"
            // url="https://cloudinary.com/console/media_library/folders/all/"
            title="Media Library"
            layout="pils"
            type="child"
            icon="photo"
            open
          />
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            isActive={pathname === '/team-members'}
            isFixed={isOpen}
            icon="contacts"
            title="Team Members"
            layout="pils"
            url="/team-members"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/portfolio-items'}
            isFixed={isOpen}
            icon="subscriptions"
            title="Portfolio Items"
            layout="pils"
            url="/portfolio-items"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/scan-projects'}
            isFixed={isOpen}
            icon="subscriptions"
            title="Scan Projects"
            layout="pils"
            url="/scan-projects"
            type="child"
            open
          />
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            isActive={pathname === '/forms'}
            title="Forms"
            url="/forms"
            icon="storage"
            isFixed={isOpen}
            layout="pils"
            type="child"
            open
          />
          <SidebarItem
            isActive={pathname === '/form-submissions'}
            title="Submissions"
            url="/form-submissions"
            icon="mail"
            isFixed={isOpen}
            layout="pils"
            type="child"
            open
          />
        </SidebarContainer>

        <SidebarContainer bottomLine user>
          <SidebarItem
            onClick={() => setSettingsToggle(!settingsToggle)}
            // isActive={isActiveSettings}
            open={settingsToggle}
            isFixed={isOpen}
            title="Settings"
            layout="pills"
            type="parent"
            icon="settings"
          />
          <SidebarItem
            isActive={pathname === '/dictionary'}
            open={settingsToggle}
            title="Dictionary"
            url="/dictionary"
            isFixed={isOpen}
            icon="translate"
            layout="pils"
            type="child"
          />
          <SidebarItem
            isActive={pathname === '/general'}
            title="General Settings"
            open={settingsToggle}
            isFixed={isOpen}
            url="/general/en"
            layout="pils"
            type="child"
            icon="extension"
          />
        </SidebarContainer>
      </SidebarWrapper>
    </SidebarStyles>
  )
}

export default Sidebar
