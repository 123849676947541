/* eslint-disable import/extensions */
import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useParams } from 'react-router'
import { dataFormatter } from '../../../helpers/data-formatter'
import FormProvider from '../../../form2/form.provider'
import { SCAN_PROJECT_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { STATUS_OPTIONS, SCAN_LOCALE_OPTIONS } from '../../../config/select-options.config'
import { PageHeader, FlexItem } from '../../../layout'
import { NotificationContext } from '../../../contexts/notification.context'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { SCAN_PROJECT_QUERY } from '../../../graphql/scan-project/queries/scan-project'
import {
  UPDATE_SCAN_PROJECT_MUTATION,
  DELETE_SCAN_PROJECT_MUTATION,
} from '../../../graphql/scan-project/mutations/scan-project'
import { IScanProject } from '../../../interfaces/scan-project/scan-project.interface'

const ScanProjectDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { locale: qLocale, slug: qSlug } = useParams() as { locale: string; slug: string }
  const capsLocale = qLocale.toUpperCase()
  const [scanProjectID, setScanProjectID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IScanProject>()
  const [formOptions, setFormOptions] = useState<IOption[]>()

  // QUERY
  const { error: queryError, data: queryData } = useQuery(SCAN_PROJECT_QUERY, {
    variables: { locale: capsLocale, slug: qSlug },
  })
  useEffect(() => {
    if (queryData && queryData.scanProject) {
      const { scanProject } = queryData
      const fd = dataFormatter(scanProject, {
        jsonFields: ['info', 'data'],
      })
      const { id, ...formattedData } = fd
      setScanProjectID(id)
      setSnapshot(formattedData as IScanProject)

      // Forms
      if (queryData.forms && queryData.forms.length) {
        setFormOptions(
          queryData.forms.map(({ id: cId, alias: cAlias }: { id: string; alias: string }) => ({
            label: cAlias,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  // MUTATIONS
  const [updateScanProject, { error: mutationError, data: mutationData }] = useMutation(UPDATE_SCAN_PROJECT_MUTATION)
  const [deleteScanProject, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_SCAN_PROJECT_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateScanProject) {
      const { updateScanProject: updateScanProjectData } = mutationData
      const fd = dataFormatter(updateScanProjectData, {
        jsonFields: ['info', 'data'],
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Scan Project Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting Scan Project!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Scan Project Deleted!' })
      setTimeout(() => {
        window.location.href = `/scan-projects`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  // ONEVENT FUNCTIONS
  // eslint-disable-next-line
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateScanProject({ variables: { id: scanProjectID, data: newData } })
  }

  const onDelete = () => {
    deleteScanProject({ variables: { id: scanProjectID } })
  }

  // COMPONENT LOGIC
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { scanProject } = queryData
  const { locale, title, slug } = scanProject as IScanProject

  return (
    <div>
      <PageHeader title={`Scan Project Details - ${title} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onSubmit={onUpdate}
            onDelete={onDelete}
            deleteVerifier={slug}
            schema={SCAN_PROJECT_UPDATE_SCHEMA}
            variables={{
              STATUS_OPTIONS,
              SCAN_LOCALE_OPTIONS,
              FORM_OPTIONS: formOptions || [],
            }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default ScanProjectDetailsPage
