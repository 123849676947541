/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const UPDATE_GENERAL_MUTATION = gql`
  mutation UpdateGeneral($id: ObjectID!, $data: GeneralUpdateInput!) {
    updateGeneral(where: { id: $id }, data: $data) {
      id
      locale
      seo {
        title
        description
        keywords
        image
      }
      info {
        type
        value
      }
      social {
        type
        value
      }
      navigation {
        type
        menu {
          id
          name
          slug
        }
      }
      partners {
        alt
        url
      }
      pages {
        homepage
        blogPostDetails
        blogPostList
        blogPostListByCategory
      }
    }
  }
`

export const CREATE_GENERAL_MUTATION = gql`
  mutation CreateGeneral($data: GeneralCreateInput!) {
    createGeneral(data: $data) {
      id
      locale
      seo {
        title
        description
        keywords
        image
      }
      info {
        type
        value
      }
      social {
        type
        value
      }
      navigation {
        type
        menu {
          id
          name
          slug
        }
      }
      partners {
        alt
        url
      }
      pages {
        homepage
        blogPostDetails
        blogPostList
        blogPostListByCategory
      }
    }
  }
`
