import styled from 'styled-components/macro'
import rem from '../../../helpers'
import { colors, fonts } from '../../../layout'
import { INPUT_BORDER_RADIUS } from '../../../config/layout.config'

interface IInputStyles {
  moduleInput?: boolean
  alternative?: boolean
  disabled?: boolean
  dark?: boolean
}

export const InputStyles = styled('input')<IInputStyles>`
  border: 1px solid ${colors.lightMidGrey};
  padding: ${rem(12)} ${rem(10)} ${rem(12)} ${rem(25)};
  border-radius: ${INPUT_BORDER_RADIUS};
  font-family: ${fonts.osc};
  font-size: 0.85rem;
  transition: 1s;
  outline: none;
  width: 100%;
  &:focus {
    box-shadow: 0 0px 4px 3px ${colors.focus};
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    color: #b7b7b7;
    opacity: .6;
    user-select: none;
    -webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
  `}

  ${({ moduleInput }) =>
    moduleInput &&
    `
    color: ${colors.almostBlack};
    background-color: transparent;
    border: 0px solid black;
    padding-left: 20px;
    position: relative;
    font-weight: 300;
    box-shadow: none;
    font-size: 16px;
    user-select: none;
  `}

  ${({ alternative, dark }) =>
    alternative &&
    !dark &&
    `
    color: ${colors.white};
    background-color: ${colors.lightMidGrey};
    border: 1px solid ${colors.lightMidGrey};
    box-shadow: none;
  `}

  ${({ alternative, dark }) =>
    alternative &&
    dark &&
    `
    color: ${colors.white};
    background-color: ${colors.darkMidGrey};
    border: 1px solid ${colors.darkMidGrey};
    box-shadow: none;
  `}
`

export const Label = styled('label')<{ alternative?: boolean; dark?: boolean }>`
  margin: 0px 0px 0px 0.1rem;
  color: ${colors.darkGrey};
  font-family: ${fonts.osc};
  font-weight: 200;
  font-size: 14px;
  ${({ alternative, dark }) =>
    alternative &&
    !dark &&
    `
    color: ${colors.white};
  `}

  ${({ alternative, dark }) =>
    alternative &&
    dark &&
    `
    color: ${colors.white};
  `}
`
export const ImagePreview = styled('div')`
  text-align: center;
  background-color: #2f2e2e;
  margin-top: -10px;
  padding-top: 25px;
  padding-bottom: 25px;
  img {
    max-width: 100%;
    max-height: 200px;
    text-align: center;
  }
`

export const Grab = styled.span`
  background-color: transparent;
  position: absolute;
  display: block;
  height: 100%;
  cursor: grab;
  z-index: 33;
  width: 50%;
  left: 0;
  top: 0;
`
