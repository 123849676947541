import FormPopup from './types/form'
import DeletePopup from './types/delete'
import ConfirmPopup from './types/confirm'
import MediaPopup from './types/media'
import MediaDetailsPopup from './types/media/media-details'

export const FORM_POPUP = 'FormPopup'
export const DELETE_POPUP = 'DeletePopup'
export const CONFIRM_POPUP = 'ConfirmPopup'
export const MEDIA_POPUP = 'MediaPopup'
export const MEDIA_DETAILS_POPUP = 'MediaDetailsPopup'

export interface IPopupList {
  FormPopup: string
  DeletePopup: string
  MediaPopup: string
  MediaDetailsPopup: string
  [key: string]: string
}

export default {
  FormPopup,
  DeletePopup,
  ConfirmPopup,
  MediaPopup,
  MediaDetailsPopup,
}
