import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'querystring'
import { useLazyQuery, useMutation } from 'react-apollo'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { removeFalsy } from '../../../helpers/object.helper'
import { usePushQuery } from '../../../helpers/query-string.helper'
import { Grid, PageHeader, FlexItem, Button } from '../../../layout'
import { FilterDropdown, FilterSearch, Pagination } from '../../../components'
import { LOCALE_OPTIONS, BLOG_CATEGORIES_ORDER_BY_OPTIONS, STATUS_OPTIONS } from '../../../config/select-options.config'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import { Table, Thead, Th, Tbody, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import DarkModeContext from '../../../contexts/darkmode.context'
import NotificationContext from '../../../contexts/notification.context'
import { DEFAULT_LOCALE, DEFAULT_STATUS } from '../../../config/variables.config'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import { BLOG_CATEGORY_CREATE_SCHEMA } from '../../../schemas/schema.map'
import PopupContext from '../../../contexts/popup.context'
import { BLOG_CATEGORIES_QUERY } from '../../../graphql/blog-category/queries/blog-category'
import { CREATE_BLOG_CATEGORY_MUTATION } from '../../../graphql/blog-category/mutations/blog-category'
import { IBlogCategory } from '../../../interfaces/blog-category/blog-category.interface'

interface IBlogCategoryListQuery {
  locale?: string
  search?: string
  status?: string
  orderBy?: string
  skip?: string
  limit?: string
}

const DEFAULT_LIMIT = 10
const DEFAULT_SKIP = 0
const DEFAULT_ORDER_BY = 'name_ASC'

const BlogCategoryListPage: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { search: locationSearch } = location
  const [query, setQuery] = useState<IBlogCategoryListQuery>({ limit: '10' })
  const popupCtx = useContext(PopupContext)
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const notificationCtx = useContext(NotificationContext)

  /**
   * QUERY
   */
  const [getList, { error: queryError, data: queryData, loading }] = useLazyQuery(BLOG_CATEGORIES_QUERY, {
    variables: query,
  })
  useEffect(() => {
    if (query) {
      const searchQuery = queryString.parse(locationSearch.substr(1))
      // set defaults
      const {
        orderBy = DEFAULT_ORDER_BY,
        limit = DEFAULT_LIMIT,
        skip = DEFAULT_SKIP,
      } = searchQuery as IBlogCategoryListQuery
      setQuery({ ...removeFalsy({ ...searchQuery, orderBy, limit }), skip })
      getList()
    }
  }, [locationSearch])

  // MUTATION
  const [createBlogCategory, { error: mutationError, data: mutationData }] = useMutation(CREATE_BLOG_CATEGORY_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.createBlogCategory) {
      const { createBlogCategory: createBlogCategoryData } = mutationData
      window.location.href = `blog-category/${createBlogCategoryData.slug}/${createBlogCategoryData.locale}`
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Blog Category successfully created!' })
    }
  }, [mutationData])

  // POPUPS
  const onClickCreate = () => {
    const initialPageData = {
      locale: DEFAULT_LOCALE,
      status: DEFAULT_STATUS,
    }

    popupCtx.show({
      id: 'create-blog-category',
      type: FORM_POPUP,
      options: {
        schema: BLOG_CATEGORY_CREATE_SCHEMA,
        data: initialPageData,
        variables: { STATUS_OPTIONS, LOCALE_OPTIONS },
      },
      submit: (cbdata: any) => createBlogCategory({ variables: { data: cbdata } }),
    })
  }

  /**
   * COMPONENT LOGIC
   */
  const [pushQuery] = usePushQuery(location, history)

  if (queryError) return <div>error</div>
  const { blogCategoryCount, blogCategories: qBlogCategories = [] } =
    queryData || ({} as { blogCategoryCount: number; blogCategories: IBlogCategory[] })
  const blogCategories: IBlogCategory[] = qBlogCategories as IBlogCategory[]

  return (
    <div>
      <PageHeader title="Blog Categories" withBackground />
      <FlexItem margin="-75px 25px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark={dark}>Actions</SectionHeader>
            <Grid mdCol="1fr auto" gap="20px" alignContent="end" justifyContent="start" alignItems="end">
              <FilterSearch
                name="search"
                label="Search (in name and slug)"
                value={query.search}
                onChange={(value) => pushQuery('search', value)}
              />
              <Button ghost onClick={onClickCreate}>
                Create New Blog Category
              </Button>
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Filter</SectionHeader>
            <Grid mdCol={3} gap="20px">
              <FilterDropdown
                name="locale"
                label="Locale"
                value={query.locale ? { value: query.locale, label: query.locale } : undefined}
                options={LOCALE_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('locale', value)}
              />
              <FilterDropdown
                name="orderBy"
                label="Order By"
                value={query.orderBy ? { value: query.orderBy, label: query.orderBy } : undefined}
                options={BLOG_CATEGORIES_ORDER_BY_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('orderBy', value)}
              />
              <FilterDropdown
                name="status"
                label="Status"
                value={query.status ? { value: query.status, label: query.status } : undefined}
                options={STATUS_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('status', value)}
              />
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Results</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Name</Th>
                  <Th>Status</Th>
                  <Th>Locale</Th>
                  <Th>id</Th>
                </Thead>
                <Tbody>
                  {loading && <p>Loading...</p>}
                  {!!blogCategories.length &&
                    blogCategories.map(({ id, name, locale, slug, status }) => {
                      return (
                        <Tr key={id}>
                          <Td>
                            <NavLink to={`/blog-category/${slug}/${locale}`} className="sidebar__item__title">
                              {name}
                            </NavLink>
                          </Td>
                          <Td>{status}</Td>
                          <Td>{LOCALE_OPTIONS.find(({ value }) => value === locale)?.label}</Td>
                          <Td>{id}</Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </Grid>
            <Pagination query={query} pushQuery={pushQuery} totalCount={blogCategoryCount} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default BlogCategoryListPage
