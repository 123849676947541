/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { isEqual } from 'lodash'
import { IInputProps } from '../../form.provider'
import ObjectField from '../object/object.field'
import { ESchemaType } from '../../../enums/schema/schema.enum'
import { IField, IOption } from '../../../interfaces/schema/schema.interface'
import { generateMongoObjectID } from '../../../helpers/mongoid-generator.helper'
import { Button, Grid, colors } from '../../../layout'
import FilterDropdown from '../../../components/filter-dropdown'
import modulesMap, { MODULE_TYPE_OPTIONS, IModuleList } from '../../../config/modules.config'
import { INPUT_BORDER_RADIUS } from '../../../config/layout.config'
import { ArrayFieldButtonWrapper } from './array.field.styles'

const ArrayField: React.FC<IInputProps> = (props) => {
  const [moduleType, setModuleType] = useState<string>()

  const [snapshot, setSnapshot] = useState<any>()
  const { setValue: pSetValue, value, fieldInfo, getValues, providedVars, requiredVars } = props
  const { name, fields, config } = fieldInfo
  const { isModuleArray = false } = config

  useEffect(() => {
    if (!isEqual(snapshot, value)) setSnapshot(value)
  }, [value])

  useEffect(() => {
    if ((snapshot || snapshot === '') && !isEqual(snapshot, value)) {
      pSetValue({ [name as string]: snapshot })
    }
  }, [snapshot])

  const setValue = (v: any, actualIndex: number) => {
    const newSnapshot = value.map((original: any, index: number) => {
      if (index !== actualIndex) {
        return original
      }
      return v.ARRAY
    })
    setSnapshot(newSnapshot)
  }

  const onRemoveItem = (index: number) => {
    const newSnapshot = snapshot.filter((data: any, i: number) => index !== i)
    setSnapshot(newSnapshot)
  }

  const onAddNewItem = () => {
    const newItem: any = {}
    if (fields && fields.length) {
      fields.map((field: IField) => {
        if (field.type === 'OBJECT_ID') newItem[field.name] = generateMongoObjectID()
        // if the array is a module array (config.isModuleArray === true)
        // we need to provide prefilled MODULE_TYPE
        else if (isModuleArray && field.type === 'MODULE_TYPE') {
          newItem[field.name] = moduleType || MODULE_TYPE_OPTIONS[0].value
        } else if (isModuleArray && (field.type === 'MODULE_DATA' || field.type === 'MODULE_INFO')) {
          const modules: IModuleList = modulesMap as any
          const defaultData = modules[moduleType || MODULE_TYPE_OPTIONS[0].value] as any
          newItem[field.name] = defaultData

          if (field.type === 'MODULE_INFO') {
            newItem[field.name] = modules.Info
          }
        } else newItem[field.name] = ''
        return ''
      })
    }
    const newSnapshot = [...snapshot, newItem]

    setSnapshot(newSnapshot)
    return ''
  }

  // DnD
  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  const onDragEnd = (result: any) => {
    if (!result.destination) return ''
    if (result.destination.index === result.source.index) return ''
    const newSnapshot: string[] = reorder(snapshot, result.source.index, result.destination.index) as string[]
    setSnapshot(newSnapshot)
    return ''
  }

  if (!snapshot) return null

  return (
    <div style={{ width: '100%', boxSizing: 'border-box' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={name}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {snapshot.map((data: string, index: number) => {
                return (
                  <Draggable key={`${name}[${index}]`} draggableId={`${name}[${index}]`} index={index}>
                    {(_provided, _snapshot) => {
                      const style = {
                        margin: '10px',
                        padding: '12px',
                        backgroundColor: 'transparent',
                        border: `1px solid ${colors.darkMidGrey}`,
                        borderRadius: INPUT_BORDER_RADIUS,
                        ..._provided.draggableProps.style,
                      }
                      return (
                        <div style={{ position: 'relative' }}>
                          <div
                            ref={_provided.innerRef}
                            {..._provided.draggableProps}
                            {..._provided.dragHandleProps}
                            style={style}
                          >
                            <Grid gap="20px">
                              <ObjectField
                                setValue={setValue}
                                getValues={getValues}
                                value={data}
                                fieldInfo={{ name: 'ARRAY', type: '' as ESchemaType, config: {}, fields }}
                                requiredVars={requiredVars}
                                providedVars={providedVars}
                                actualIndex={index}
                              />
                              <ArrayFieldButtonWrapper isModuleField={isModuleArray}>
                                <Button type="button" warning onClick={() => onRemoveItem(index)}>
                                  Remove Item
                                </Button>
                              </ArrayFieldButtonWrapper>
                            </Grid>
                          </div>
                        </div>
                      )
                    }}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Grid
        alignItems="end"
        mdCol={isModuleArray ? '1fr auto' : 'auto'}
        paddingTop="40px"
        paddingLeft="10px"
        paddingRight="10px"
        gap="20px"
      >
        {isModuleArray && (
          <FilterDropdown
            name="type"
            label="Module Type"
            value={MODULE_TYPE_OPTIONS.find(({ value: v }) => v === moduleType)}
            options={MODULE_TYPE_OPTIONS as [IOption]}
            onChange={(v) => setModuleType(v)}
          />
        )}
        <Button
          type="button"
          onClick={onAddNewItem}
          {...(isModuleArray && { disabled: !moduleType || moduleType === 'select' })}
        >
          Add New Item
        </Button>
      </Grid>
    </div>
  )
}

export default ArrayField
