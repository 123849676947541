/* eslint-disable react/prop-types */
import React from 'react'
import { Backdrop, PopupWrapper, CloseButtonWrapper } from './popup.styles'
import { FORM_POPUP } from '../../popup.map'
import FormProvider from '../../../../form2/form.provider'
import { Button } from '../../../../layout'
import { CloseButton } from '../../../../layout/button/button.styles'

const FormPopup: React.FC<any> = props => {
  const { id, type, submit, hide, options } = props
  const { schema, data, variables } = options

  if (type !== FORM_POPUP) return <div>Invalid popup type!</div>
  // @ TODO validations!

  const onSubmit = (formData: any) => {
    submit(formData)
    hide(id)
  }

  const onClose = () => {
    hide(id)
  }

  return (
    <Backdrop>
      <PopupWrapper>
        <CloseButton onClick={onClose}>✕</CloseButton>
        <FormProvider onSubmit={onSubmit} schema={schema} variables={variables} data={data} popup />
        <CloseButtonWrapper>
          <Button warning ghost onClick={onClose} type="button">
            Close
          </Button>
        </CloseButtonWrapper>
      </PopupWrapper>
    </Backdrop>
  )
}

export default FormPopup
