/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const GENERAL_QUERY = gql`
  query General($locale: Locale!) {
    general(where: { locale: $locale }) {
      id
      locale
      seo {
        title
        description
        keywords
        image
      }
      info {
        type
        value
      }
      social {
        type
        value
      }
      navigation {
        type
        menu {
          id
          name
          slug
        }
      }
      partners {
        alt
        url
      }
      pages {
        homepage
        blogPostDetails
        blogPostList
        blogPostListByCategory
      }
    }

    menus {
      id
      name
      locale
    }
  }
`
