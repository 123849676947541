import styled from 'styled-components/macro'
import { colors, fonts } from '../../../../../layout'
import rem from '../../../../../helpers/rem.helper'

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  color: ${colors.body};
  z-index: 10050;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`

export const PopupWrapper = styled.div`
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  border-radius: ${rem(5)};
  margin: ${rem(50)} auto;
  max-width: ${rem(1000)};
  padding: ${rem(50)};
`

export const CloseButton = styled.button`
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  font-size: ${rem(20)};
  padding: ${rem(15)};
  position: absolute;
  border: none;
  right: 0;
  top: 0;
  &:hover {
    color: ${colors.main};
  }
`

export const Attribute = styled.p`
  text-align: right;
  font-family: ${fonts.osc};
  line-height: 1.3;
`

export const InfoValue = styled.p`
  word-break: break-all;
  font-family: ${fonts.osc};
  line-height: 1.3;
`

export const Heading = styled.h1`
  word-break: break-all;
  font-family: ${fonts.osc};
  line-height: 1.3;
  font-size: 2rem;
  color: ${colors.main};
  font-weight: 300;
`

export const PreviewWrapper = styled.div`
  border-right: 1px solid ${colors.lightMidGrey};
  padding-right: 50px;
  margin-right: 50px;
  margin-left: auto;
`

export const ImagePreview = styled.img`
  max-height: 400px;
  max-width: 400px;
`
