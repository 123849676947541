/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const BLOG_CATEGORY_QUERY = gql`
  query BlogCategory($slug: String!, $locale: Locale!) {
    blogCategory(where: { slug: $slug, locale: $locale }) {
      id
      locale
      status
      name
      slug
    }
  }
`

export const BLOG_CATEGORIES_QUERY = gql`
  query BlogCategories(
    $locale: Locale
    $search: String
    $orderBy: BlogCategoryOrderByInput
    $skip: Int
    $limit: Int
    $status: Status
  ) {
    blogCategories(
      where: { locale: $locale, status: $status }
      searchInNameAndSlug: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      id
      locale
      status
      name
      slug
      updated_at
    }

    blogCategoryCount(where: { locale: $locale, status: $status }, searchInNameAndSlug: $search)
  }
`
