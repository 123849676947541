import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'querystring'
import { useLazyQuery, useMutation } from 'react-apollo'
import { usePushQuery } from '../../../helpers/query-string.helper'
import { removeFalsy } from '../../../helpers/object.helper'
import { PAGES_QUERY } from '../../../graphql/page/queries/page'
import { IPage } from '../../../interfaces/page/page.interface'
import { Grid, PageHeader, FlexItem, Button } from '../../../layout'
import { FilterSearch, FilterDropdown, Pagination } from '../../../components'
import { LOCALE_OPTIONS, STATUS_OPTIONS, PAGES_ORDER_BY_OPTIONS } from '../../../config/select-options.config'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { Table, Thead, Tbody, Th, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import PopupContext from '../../../contexts/popup.context'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import { CREATE_PAGE_MUTATION } from '../../../graphql/page/mutations/page'
import { PAGE_CREATE_SCHEMA } from '../../../schemas/schema.map'
import { DEFAULT_LOCALE, DEFAULT_STATUS } from '../../../config/variables.config'
import NotificationContext from '../../../contexts/notification.context'
import DarkModeContext from '../../../contexts/darkmode.context'
import ClonePage from '../../../components/clone-page'

export interface IPageListQuery {
  locale?: string
  search?: string
  status?: string
  orderBy?: string
  skip?: string
  limit?: string
}

const DEFAULT_LIMIT = 10
const DEFAULT_SKIP = 0
const DEFAULT_ORDER_BY = 'alias_ASC'

const PageListPage = () => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const location = useLocation()
  const history = useHistory()
  const { search: locationSearch } = location
  const [query, setQuery] = useState<IPageListQuery>({ limit: '10' })

  // QUERY
  const [getList, { error: queryError, data: queryData, loading }] = useLazyQuery(PAGES_QUERY, { variables: query })
  useEffect(() => {
    if (query) {
      const searchQuery = queryString.parse(locationSearch.substr(1))
      // set defaults
      const { orderBy = DEFAULT_ORDER_BY, limit = DEFAULT_LIMIT, skip = DEFAULT_SKIP } = searchQuery as IPageListQuery
      setQuery({ ...removeFalsy({ ...searchQuery, orderBy, limit }), skip })
      getList()
    }
  }, [locationSearch])

  // MUTATION
  const [createPage, { error: mutationError, data: mutationData }] = useMutation(CREATE_PAGE_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.createPage) {
      const { createPage: createPageData } = mutationData
      window.location.href = `page/${createPageData.id}/${createPageData.locale}`
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Successfully created!' })
    }
  }, [mutationData])

  // POPUPS
  const onClickCreate = () => {
    const initialPageData = {
      locale: DEFAULT_LOCALE,
      status: DEFAULT_STATUS,
      seo: {
        create: {
          title: '',
        },
      },
    }

    popupCtx.show({
      id: 'create-page',
      type: FORM_POPUP,
      options: {
        schema: PAGE_CREATE_SCHEMA,
        data: initialPageData,
        variables: { STATUS_OPTIONS, LOCALE_OPTIONS },
      },
      submit: (cbdata: any) => createPage({ variables: { data: cbdata } }),
    })
  }

  //  COMPONENT LOGIC
  const [pushQuery] = usePushQuery(location, history)

  if (queryError) return <div>error</div>
  const { pageCount, pages: qPages = [] } = queryData || ({} as { pageCount: number; pages: IPage[] })
  const pages: IPage[] = qPages as IPage[]

  return (
    <div>
      <PageHeader title="Pages" withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark={dark}>Actions</SectionHeader>
            <Grid mdCol="1fr auto" gap="20px" alignContent="end" justifyContent="start" alignItems="end">
              <FilterSearch
                name="search"
                label="Search (in name and slug)"
                value={query.search}
                onChange={(value) => pushQuery('search', value)}
              />
              <Button ghost onClick={onClickCreate}>
                Create New Page
              </Button>
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Filter</SectionHeader>
            <Grid mdCol={3} gap="20px">
              <FilterDropdown
                name="locale"
                label="Locale"
                value={query.locale ? { value: query.locale, label: query.locale } : undefined}
                options={LOCALE_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('locale', value)}
              />
              <FilterDropdown
                name="orderBy"
                label="Order By"
                value={query.orderBy ? { value: query.orderBy, label: query.orderBy } : undefined}
                options={PAGES_ORDER_BY_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('orderBy', value)}
              />
              <FilterDropdown
                name="status"
                label="Status"
                value={query.status ? { value: query.status, label: query.status } : undefined}
                options={STATUS_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('status', value)}
              />
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Results</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Title</Th>
                  <Th>Alias</Th>
                  <Th>Slug</Th>
                  <Th>Status</Th>
                  <Th>Locale</Th>
                  <Th>id</Th>
                  <Th />
                </Thead>
                <Tbody>
                  {loading && <p>Loading...</p>}
                  {!!pages.length &&
                    pages.map(({ id, title, locale, slug, status, alias }) => {
                      return (
                        <Tr key={id}>
                          <Td>
                            <NavLink to={`/page/${id}/${locale}`}>{title}</NavLink>
                          </Td>
                          <Td>{alias}</Td>
                          <Td>{slug}</Td>
                          <Td>{status}</Td>
                          <Td>{LOCALE_OPTIONS.find(({ value }) => value === locale)?.label}</Td>
                          <Td>{id}</Td>
                          <Td>
                            <ClonePage refreshList={getList} id={id} />
                          </Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </Grid>
            <Pagination totalCount={pageCount} pushQuery={pushQuery} query={query} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default PageListPage
