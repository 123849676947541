import MenuUpdateSchema from './menu/menu-update.schema.json'
import MenuCreateSchema from './menu/menu-create.schema.json'
import MenuLinksSchema from './menu/menu-links.schema.json'
import GeneralUpdateSchema from './general/general-update.schema.json'
import GeneralCreateSchema from './general/general-create.schema.json'
import PageCreateSchema from './page/page-create.schema.json'
import PageUpdateSchema from './page/page-update.schema.json'
import TranslationCreateSchema from './translation/translation-create.schema.json'
import TranslationUpdateSchema from './translation/translation-update.schema.json'
import FormUpdateSchema from './form/form-update.schema.json'
import FormCreateSchema from './form/form-create.schema.json'
import FormFieldsSchema from './form/form-fields.schema.json'
import FormFieldsOptionsSchema from './form/form-fields-options.schema.json'
import FormSubmissionUpdateSchema from './form-submission/form-submission-update.schema.json'
// Blog
import BlogCategoryUpdateSchema from './blog-category/blog-category-update.schema.json'
import BlogCategoryCreateSchema from './blog-category/blog-category-create.schema.json'
import BlogPostCreateSchema from './blog-post/blog-post-create.schema.json'
import BlogPostUpdateSchema from './blog-post/blog-post-update.schema.json'
// Team Member
import TeamMemberCreateSchema from './team-member/team-member-create.schema.json'
import TeamMemberUpdateSchema from './team-member/team-member-update.schema.json'
// Portfolio Item
import PortfolioItemCreateSchema from './portfolio-item/portfolio-item-create.schema.json'
import PortfolioItemUpdateSchema from './portfolio-item/portfolio-item-update.schema.json'
// Scan Project
import ScanProjectCreateSchema from './scan-project/scan-project-create.schema.json'
import ScanProjectUpdateSchema from './scan-project/scan-project-update.schema.json'
// Module defaults
import Info from './modules/info.schema.json'
import ButtonSchema from './modules/button.schema.json'
import ImageSchema from './modules/image.schema.json'
// Modules
import ContactModule from './modules/contact.schema.json'
import FormModule from './modules/form.schema.json'
import HeroModule from './modules/hero-module.schema.json'
import HighlightsModule from './modules/highlights.schema.json'
import ImageModule from './modules/image-module.schema.json'
import MainHeroModule from './modules/mainhero-module.schema.json'
import NavigationModule from './modules/navigation-module.schema copy.json'
import NextItemModule from './modules/next-item.schema.json'
import PortfolioHighlightsModule from './modules/portfolio-highlights.schema.json'
import PortfolioTimelineModule from './modules/portfolio-timeline.schema.json'
import SocialModule from './modules/social.schema.json'
import TextAndButtonModule from './modules/textandbutton-module.schema.json'
import TextModule from './modules/text-module.schema.json'
import YoutubeEmbedModule from './modules/youtube-embed-module.schema .json'
// SCAN Modules
import ScanContactModule from './modules/scan-contact.schema.json'
import ScanHeroModule from './modules/scan-hero-module.schema.json'
import ScanImageCompareModule from './modules/scan-image-compare.schema.json'
import ScanPartnersModule from './modules/scan-partners.schema.json'
import ScanProjectsModule from './modules/scan-projects.schema.json'
import ScanTextImageModule from './modules/scan-text-image-module.schema.json'

export const MENU_UPDATE_SCHEMA = 'MenuUpdateSchema'
export const MENU_CREATE_SCHEMA = 'MenuCreateSchema'
export const GENERAL_UPDATE_SCHEMA = 'GeneralUpdateSchema'
export const GENERAL_CREATE_SCHEMA = 'GeneralCreateSchema'
export const PAGE_UPDATE_SCHEMA = 'PageUpdateSchema'
export const PAGE_CREATE_SCHEMA = 'PageCreateSchema'
export const TRANSLATION_CREATE_SCHEMA = 'TranslationCreateSchema'
export const TRANSLATION_UPDATE_SCHEMA = 'TranslationUpdateSchema'
export const FORM_UPDATE_SCHEMA = 'FormUpdateSchema'
export const FORM_CREATE_SCHEMA = 'FormCreateSchema'
export const FORM_SUBMISSION_UPDATE_SCHEMA = 'FormSubmissionUpdateSchema'
// Blog
export const BLOG_CATEGORY_UPDATE_SCHEMA = 'BlogCategoryUpdateSchema'
export const BLOG_CATEGORY_CREATE_SCHEMA = 'BlogCategoryCreateSchema'
export const BLOG_POST_UPDATE_SCHEMA = 'BlogPostUpdateSchema'
export const BLOG_POST_CREATE_SCHEMA = 'BlogPostCreateSchema'

// Team Member
export const TEAM_MEMBER_UPDATE_SCHEMA = 'TeamMemberUpdateSchema'
export const TEAM_MEMBER_CREATE_SCHEMA = 'TeamMemberCreateSchema'

// Portfolio Item
export const PORTFOLIO_ITEM_UPDATE_SCHEMA = 'PortfolioItemUpdateSchema'
export const PORTFOLIO_ITEM_CREATE_SCHEMA = 'PortfolioItemCreateSchema'

// Scan Project
export const SCAN_PROJECT_UPDATE_SCHEMA = 'ScanProjectUpdateSchema'
export const SCAN_PROJECT_CREATE_SCHEMA = 'ScanProjectCreateSchema'

export interface ISchemaList {
  MenuUpdateSchema: string
  MenuCreateSchema: string
  MenuLinksSchema: string
  GeneralUpdateSchema: string
  GeneralCreateSchema: string
  PageUpdateSchema: string
  PageCreateSchema: string
  TranslationUpdateSchema: string
  TranslationCreateSchema: string
  FormUpdateSchema: string
  FormCreateSchema: string
  FormFieldsSchema: string
  FormFieldsOptionsSchema: string
  FormSubmissionUpdateSchema: string
  // Blog
  BlogCategoryUpdateSchema: string
  BlogCategoryCreateSchema: string
  BlogPostUpdateSchema: string
  BlogPostCreateSchema: string
  // Team Member
  TeamMemberUpdateSchema: string
  TeamMemberCreateSchema: string
  // Portfolio Item
  PortfolioItemUpdateSchema: string
  PortfolioItemCreateSchema: string
  // Scan Project
  ScanProjectUpdateSchema: string
  ScanProjectCreateSchema: string
  // Module defaults
  Info: string
  ButtonSchema: string
  Button2Schema: string
  ImageSchema: string
  // Modules
  ContactModule: string
  FormModule: string
  HeroModule: string
  HighlightsModule: string
  ImageModule: string
  MainHeroModule: string
  NavigationModule: string
  NextItemModule: string
  PortfolioHighlightsModule: string
  PortfolioTimelineModule: string
  SocialModule: string
  TextAndButtonModule: string
  TextModule: string
  YoutubeEmbedModule: string
  // SCAN Modules
  ScanContactModule: string
  ScanHeroModule: string
  ScanImageCompareModule: string
  ScanPartnersModule: string
  ScanProjectsModule: string
  ScanTextImageModule: string

  [key: string]: string
}

export default {
  MenuUpdateSchema,
  MenuCreateSchema,
  MenuLinksSchema,
  GeneralUpdateSchema,
  GeneralCreateSchema,
  PageUpdateSchema,
  PageCreateSchema,
  TranslationUpdateSchema,
  TranslationCreateSchema,
  FormUpdateSchema,
  FormCreateSchema,
  FormFieldsSchema,
  FormFieldsOptionsSchema,
  FormSubmissionUpdateSchema,
  // Blog
  BlogCategoryUpdateSchema,
  BlogCategoryCreateSchema,
  BlogPostUpdateSchema,
  BlogPostCreateSchema,
  // Team Member
  TeamMemberCreateSchema,
  TeamMemberUpdateSchema,
  // Portfolio Item
  PortfolioItemUpdateSchema,
  PortfolioItemCreateSchema,
  // Scan Project
  ScanProjectUpdateSchema,
  ScanProjectCreateSchema,
  // Module defaults
  Info,
  ButtonSchema,
  Button2Schema: ButtonSchema,
  ImageSchema,
  // Modules
  ContactModule,
  FormModule,
  HeroModule,
  HighlightsModule,
  ImageModule,
  MainHeroModule,
  NavigationModule,
  NextItemModule,
  PortfolioHighlightsModule,
  PortfolioTimelineModule,
  SocialModule,
  TextAndButtonModule,
  TextModule,
  YoutubeEmbedModule,
  // SCAN Modules
  ScanContactModule,
  ScanHeroModule,
  ScanImageCompareModule,
  ScanPartnersModule,
  ScanProjectsModule,
  ScanTextImageModule,
}
