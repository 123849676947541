/* eslint-disable import/extensions */
import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useParams } from 'react-router'
import { dataFormatter } from '../../../helpers/data-formatter'
import FormProvider from '../../../form2/form.provider'
import { PORTFOLIO_ITEM_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { STATUS_OPTIONS, SPECTRAL_LOCALE_OPTIONS } from '../../../config/select-options.config'
import { PageHeader, FlexItem } from '../../../layout'
import { NotificationContext } from '../../../contexts/notification.context'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { PORTFOLIO_ITEM_QUERY } from '../../../graphql/portfolio-item/queries/portfolio-item'
import { IPortfolioItem } from '../../../interfaces/portfolio-item/portfolio-item.interface'
import {
  UPDATE_PORTFOLIO_ITEM_MUTATION,
  DELETE_PORTFOLIO_ITEM_MUTATION,
} from '../../../graphql/portfolio-item/mutations/portfolio-item'

const PortfolioItemDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { locale: qLocale, slug: qSlug } = useParams() as { locale: string; slug: string }
  const capsLocale = qLocale.toUpperCase()
  const [portfolioItemID, setPortfolioItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IPortfolioItem>()
  const [teamMembersOptions, setTeamMembersOptions] = useState<IOption[]>([])
  const [formOptions, setFormOptions] = useState<IOption[]>()

  // QUERY
  const { error: queryError, data: queryData } = useQuery(PORTFOLIO_ITEM_QUERY, {
    variables: { locale: capsLocale, slug: qSlug },
  })
  useEffect(() => {
    if (queryData && queryData.portfolioItem) {
      const { portfolioItem } = queryData
      const fd = dataFormatter(portfolioItem, {
        arrayOfPopulatedFieldsWithId: ['team_members'],
        jsonFields: ['info', 'data'],
      })
      const { id, ...formattedData } = fd
      setPortfolioItemID(id)
      setSnapshot(formattedData as IPortfolioItem)

      // Team Members
      if (queryData.teamMembers && queryData.teamMembers.length) {
        setTeamMembersOptions(
          queryData.teamMembers
            .map(({ id: cId, name: cName }: { id: string; name: string }) => {
              if (cId === portfolioItem.id) return null
              return {
                label: cName,
                value: cId,
              }
            })
            .filter(Boolean),
        )
      }

      // Forms
      if (queryData.forms && queryData.forms.length) {
        setFormOptions(
          queryData.forms.map(({ id: cId, alias: cAlias }: { id: string; alias: string }) => ({
            label: cAlias,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  // MUTATIONS
  const [updatePortfolioItem, { error: mutationError, data: mutationData }] = useMutation(
    UPDATE_PORTFOLIO_ITEM_MUTATION,
  )
  const [deletePortfolioItem, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_PORTFOLIO_ITEM_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updatePortfolioItem) {
      const { updatePortfolioItem: updatePortfolioItemData } = mutationData
      const fd = dataFormatter(updatePortfolioItemData, {
        arrayOfPopulatedFieldsWithId: ['team_members'],
        jsonFields: ['info', 'data'],
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Portfolio Item Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting Portfolio Item!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Portfolio Item Deleted!' })
      setTimeout(() => {
        window.location.href = `/portfolio-items`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  // ONEVENT FUNCTIONS
  // eslint-disable-next-line
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updatePortfolioItem({ variables: { id: portfolioItemID, data: newData } })
  }

  const onDelete = () => {
    deletePortfolioItem({ variables: { id: portfolioItemID } })
  }

  // COMPONENT LOGIC
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { portfolioItem } = queryData
  const { locale, title, slug } = portfolioItem as IPortfolioItem

  return (
    <div>
      <PageHeader title={`Portfolio Item Details - ${title} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onSubmit={onUpdate}
            onDelete={onDelete}
            deleteVerifier={slug}
            schema={PORTFOLIO_ITEM_UPDATE_SCHEMA}
            variables={{
              STATUS_OPTIONS,
              SPECTRAL_LOCALE_OPTIONS,
              TEAM_MEMBER_OPTIONS: teamMembersOptions,
              FORM_OPTIONS: formOptions || [],
            }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default PortfolioItemDetailsPage
