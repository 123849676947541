import styled from 'styled-components/macro'
import { colors, fonts } from '../../../layout'
import rem from '../../../helpers/rem.helper'

export const CreateNewFolderInput = styled('input')`
  padding: 6px 15px;
  margin-right: 5px;
  border-radius: 3px;
  border: 1px solid #717171;
  font-family: ${fonts.osc};
  outline: none;
`

export const BreadCrumbWrapper = styled('div')`
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.lightMidGrey};
  margin-bottom: 25px;

  form {
    display: inline-block;
    margin-left: 6px;
  }

  button {
    padding: 7px 20px;
    outline: none;
  }
`

export const BreadCrumbItem = styled('span')<{ active?: boolean }>`
  color: ${colors.midGrey};
  font-family: ${fonts.osc};
  font-size: ${rem(16)};
  cursor: pointer;

  span {
    color: ${colors.main};
    margin-right: 12px;
    margin-left: 7px;
    cursor: default;
  }

  ${({ active }) =>
    active &&
    `
      color: ${colors.main};
      cursor: default;
    `}
`

export const Folder = styled('div')`
  height: 157px;
  background-color: #e4e4e4;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;
  cursor: pointer;

  i {
    color: #4a4a4a;
    font-size: 50px;
    padding-bottom: 20px;
    padding-top: 31px;
    transition: 0.2s;
  }

  :hover {
    i {
      transform: scale(1.2);
    }
  }

  div {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px 0px;
    font-size: 11px;
    background-color: #4a4a4a;
    color: white;
    font-family: ${fonts.osc};

    p {
      margin: 0px 22px;
      text-align: center;
    }
    transition: 0.4s;
  }
`

export const MediaListItem = styled('div')<{ background?: string; acive?: boolean }>`
  height: 157px;
  background-color: #e4e4e4;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  transition: 0.4s;
  cursor: pointer;
  ${({ background }) =>
    background &&
    `
    background-image: url(${background});
    div {
      opacity: 0;
    }
    `}

  div {
    transition: 0.4s;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px 0px;
    font-size: 11px;
    background-color: #f19b3ae3;
    color: white;
    font-family: ${fonts.osc};

    p {
      margin: 0px 22px;
      text-align: center;
    }
  }

  &:hover {
    div {
      opacity: 1;
    }
    i {
      transform: scale(1.2);
    }
  }

  ${({ acive }) =>
    acive &&
    `
      background-color: white;
      border: 1px dashed #252525;

      &:hover {
        div {
          opacity: 0;
        }
      }
    `}

  & i {
    transition: transform 0.2s ease-in-out;
    color: ${colors.main};
    padding-bottom: 20px;
    transform: scale(1);
    text-align: center;
    padding-top: 31px;
    width: 100%;
  }
`

export const DeleteFolderItem = styled('div')`
  height: 157px;
  background-color: #e4e4e4;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;
  cursor: pointer;
  div {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px 0px;
    font-size: 11px;
    background-color: ${colors.error};
    color: white;
    font-family: ${fonts.osc};

    p {
      margin: 0px 22px;
      text-align: center;
    }
    transition: 0.4s;
  }

  i {
    color: ${colors.error};
    font-size: 50px;
    padding-bottom: 20px;
    padding-top: 31px;
    transition: 0.2s;
  }

  :hover {
    i {
      font-size: 70px;
      padding-bottom: 16px;
      padding-top: 16px;
    }
  }
`

export const UploadMediaItem = styled('div')`
  height: 157px;
  background-color: #e4e4e4;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  text-align: center;

  p {
    font-family: ${fonts.osc};
    font-size: 11px;
    margin-bottom: 5px;
  }

  div {
    font-family: ${fonts.osc};
    border: 1px dotted #4a4a4a;
    margin: 14px;
    padding: 21px 13px;
    font-size: 13px;
  }

  button {
    margin: auto;
  }
`

export const Loading = styled('div')`
  padding-bottom: 170px;
  width: 100%;
  /* background-color: red; */
  .spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 15px;
    height: 15px;
    background-color: ${colors.main};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`
