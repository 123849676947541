/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useRef, useContext } from 'react'
import { isEqual } from 'lodash'
import { IInputProps } from '../../form.provider'
import { Grid, Text, FlexItem, Button } from '../../../layout'
import { Label } from '../input/input.styles'
import { IOption } from '../../../interfaces/schema/schema.interface'
import DarkModeContext from '../../../contexts/darkmode.context'
import { MediaWrapper, MediaThumbnail, MediaInfo } from './media.styles'
import Icon from '../../../layout/icons/icons.styles'
import PopupContext from '../../../contexts/popup.context'
import { MEDIA_POPUP } from '../../../components/popup/popup.map'

const MediaField: React.FC<IInputProps> = (props) => {
  const popupCtx = useContext(PopupContext)
  const [snapshot, setSnapshot] = useState<string>('')
  const [newValue, setNewValue] = useState<string>()
  const { setValue, value, fieldInfo } = props
  const { name, config } = fieldInfo
  const { flex } = config
  const { required, label, alternative = false } = config
  const DarkModeCtx = useContext(DarkModeContext)
  const { dark } = DarkModeCtx

  useEffect(() => {
    if (!isEqual(snapshot, value)) setSnapshot(value)
    if (!isEqual(newValue, value)) setNewValue(value)
  }, [value])

  useEffect(() => {
    if ((newValue || newValue === '') && !isEqual(newValue, value)) {
      setValue({ [name as string]: newValue })
    }
  }, [newValue])

  useEffect(() => {
    if ((newValue || newValue === '') && !isEqual(newValue, value)) {
      setValue({ [name as string]: newValue })
    }
  }, [newValue])

  const onChange = (data: any) => {
    setNewValue(data)
  }
  return (
    <FlexItem flex={flex || '100%'} margin="0 20px 10px 0">
      <Grid alignItems="start" alignContent="start">
        {label && (
          <Label alternative={alternative} dark={dark}>
            {label}
          </Label>
        )}
        <MediaWrapper>
          <Grid mdCol="auto auto auto" gap="15px">
            <MediaThumbnail background={snapshot} />
            <MediaInfo>
              <p>... {snapshot?.substr(snapshot.length - 20)}</p>
            </MediaInfo>
            <Icon
              onClick={() => {
                popupCtx.show({
                  id: `media_popup_${value}`,
                  type: MEDIA_POPUP,
                  options: {
                    data: {
                      actualURL: snapshot,
                    },
                  },
                  submit: (url) => {
                    onChange(url)
                  },
                })
              }}
            >
              perm_media
            </Icon>
          </Grid>
          {required && (
            <input
              style={{
                position: 'absolute',
                opacity: 0,
                padding: 0,
                height: 0,
                margin: 0,
              }}
              required={required}
              autoComplete="off"
              tabIndex={-1}
              value={value}
            />
          )}
        </MediaWrapper>
      </Grid>
    </FlexItem>
  )
}

export default MediaField
