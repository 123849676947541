import styled from 'styled-components/macro'
import rem from '../../../helpers'
import { colors, fonts } from '../../../layout'

export const MediaWrapper = styled('div')`
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  padding: 1px;
  background-color: #efefef;
  i {
    border: 1px solid #4a4a4a;
    border-radius: 4px;
    padding: 8px 14px;
    font-size: 19px;
    color: #4a4a4a;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background-color: #b3b3b3;
    }
  }
`

export const MediaThumbnail = styled('div')<{ background?: string }>`
  height: 38px;
  width: 50px;
  background-color: hsla(0, 0%, 70%, 1);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 3px;

  ${({ background }) =>
    background &&
    `
    background-image: url(${background})
  `}
`

export const MediaInfo = styled('div')`
  font-family: ${fonts.osc};
  color: #6f6f6f;
  font-size: 12px;
  padding-top: 15px;
  cursor: not-allowed;
  /* border: 1px solid #b3b3b3; */
  border-radius: 4px;
  max-width: 100%;
`
