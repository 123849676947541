/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_PORTFOLIO_ITEM_MUTATION = gql`
  mutation CreatePortfolioItem($data: PortfolioItemCreateInput!) {
    createPortfolioItem(data: $data) {
      description
      locale
      status
      title
      slug
      date
      id
    }
  }
`

export const UPDATE_PORTFOLIO_ITEM_MUTATION = gql`
  mutation UpdatePortfolioItem($id: ObjectID!, $data: PortfolioItemUpdateInput!) {
    updatePortfolioItem(where: { id: $id }, data: $data) {
      thumbnail_image
      featured_image
      description
      locale
      status
      title
      slug
      date
      id
      team_members {
        position
        locale
        status
        email
        phone
        name
        slug
        id
      }
      modules {
        type
        info
        data
        id
      }
    }
  }
`

export const DELETE_PORTFOLIO_ITEM_MUTATION = gql`
  mutation DeletePortfolioItem($id: ObjectID!) {
    deletePortfolioItem(where: { id: $id }) {
      id
    }
  }
`
