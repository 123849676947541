/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { colors, fonts } from '../../../layout'
import { MAIN_BRAND_COLOR, INPUT_BORDER_RADIUS } from '../../../config/layout.config'

export const SelectWrapper = styled('div')`
  .disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
`

// React select style with emotion
export const selectStyle = {
  menu: (provided: any) => ({
    ...provided,
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.18)',
    border: `1px solid ${colors.lightMidGrey}`,
    fontFamily: fonts.osc,
    maxHeight: '250px',
    overflow: 'scroll',
    zIndex: 20,
    paddingBottom: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '245px',
    marginBottom: '0px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? `2px solid ${colors.focus}` : `1px solid ${colors.lightMidGrey}`,
    outline: 'none',
    boxShadow: 'none',
    borderRadius: INPUT_BORDER_RADIUS,
    fontFamily: fonts.osc,
    display: 'flex',
    padding: '1px',
    flex: 1,
    marginBottom: '0px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: colors.darkGrey,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? MAIN_BRAND_COLOR : null,
    color: colors.almostBlack,
    fontFamily: fonts.osc,
    padding: '10px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}

export const selectAlternativeStyle = {
  menu: (provided: any) => ({
    ...provided,
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.18)',
    border: `1px solid ${colors.lightMidGrey}`,
    fontFamily: fonts.osc,
    maxHeight: '250px',
    overflow: 'scroll',
    zIndex: 20,
    paddingBottom: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '245px',
    marginBottom: '0px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? `2px solid ${colors.focus}` : `1px solid ${colors.lightMidGrey}`,
    backgroundColor: colors.lightMidGrey,
    borderRadius: INPUT_BORDER_RADIUS,
    fontFamily: fonts.osc,
    marginBottom: '0px',
    boxShadow: 'none',
    outline: 'none',
    display: 'flex',
    padding: '1px',
    flex: 1,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: colors.white,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? MAIN_BRAND_COLOR : null,
    color: colors.body,
    fontFamily: fonts.osc,
    padding: '10px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}

export const selectAlternativeDarkStyle = {
  menu: (provided: any) => ({
    ...provided,
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.18)',
    border: `1px solid ${colors.darkGrey}`,
    backgroundColor: colors.darkMidGrey,
    fontFamily: fonts.osc,
    maxHeight: '250px',
    overflow: 'scroll',
    zIndex: 20,
    paddingBottom: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '245px',
    marginBottom: '0px',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.isFocused ? `2px solid ${colors.focus}` : `1px solid ${colors.darkMidGrey}`,
    borderRadius: INPUT_BORDER_RADIUS,
    backgroundColor: colors.darkMidGrey,
    fontFamily: fonts.osc,
    marginBottom: '0px',
    color: 'green',
    boxShadow: 'none',
    outline: 'none',
    display: 'flex',
    padding: '1px',
    flex: 1,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: colors.white,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isFocused ? MAIN_BRAND_COLOR : null,
    color: colors.white,
    fontFamily: fonts.osc,
    padding: '10px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
}
