// Default module datas

const ContactModule = {
  description: '',
  formSlug: '',
  title: '',
}

const SocialModule = {
  description: '',
  title: '',
}

const FormModule = {
  formSlug: '',
}

const HeroModule = {
  backgroundImage: '',
  description: '',
  subTitle: '',
  title: '',
}

const HighlightsModule = {
  items: [],
}

const ImageModule = {
  image: {
    url: '',
    alt: '',
  },
}

const MainHeroModule = {
  title: '',
  image: {
    url: '',
    alt: '',
  },
}

const NextItemModule = {
  projectTitle: '',
  description: '',
  title: '',
  url: '',
}

const PortfolioHighlightsModule = {
  items: [],
}

const PortfolioTimelineModule = {
  items: [],
}

const YoutubeEmbedModule = {
  embedUrl: '',
  thumbnailImg: '',
}

const TextModule = {
  content: '',
  style: 'dark',
}

const TextAndButtonModule = {
  content: '',
  style: 'dark',
  title: '',
  button: {
    url: '',
    icon: '',
    text: '',
  },
}

const NavigationModule = {
  message: '',
  button: {
    url: '',
    icon: '',
    text: '',
  },
}

const ScanContactModule = {
  description: '',
  subTitle: '',
  formSlug: '',
  title: '',
}

const ScanHeroModule = {
  title: '',
  subTitle: '',
  button: {
    url: '',
    icon: '',
    text: '',
  },
}

const ScanPartnersModule = {
  title: '',
  subTitle: '',
  partners: [{ logo: '' }],
}

const ScanImageCompareModule = {
  images: [
    {
      image: '',
    },
  ],
}

const ScanProjectsModule = {
  items: [],
}

const ScanTextImageModule = {
  title: '',
  subTitle: '',
  variant: '',
  backgroundImageUrl: '',
  description: '',
  button: {
    url: '',
    icon: '',
    text: '',
  },
  image: {
    url: '',
    alt: '',
  },
}

// Module defaults
const Info = {
  idName: '',
  className: '',
  marginTop: null,
  marginBottom: null,
  paddingTop: null,
  paddingBottom: null,
  backgroundColor: null,
}

export interface IModuleList {
  ContactModule: string
  FormModule: string
  HeroModule: string
  HighlightsModule: string
  ImageModule: string
  MainHeroModule: string
  NextItemModule: string
  PortfolioHighlightsModule: string
  PortfolioTimelineModule: string
  SocialModule: string
  TextAndButtonModule: string
  TextModule: string
  YoutubeEmbedModule: string
  // SCAN modules
  ScanContactModule: string
  ScanHeroModule: string
  ScanImageCompareModule: string
  ScanPartnersModule: string
  ScanProjectsModule: string
  ScanTextImageModule: string
  // Module defaults
  Info: string
  [key: string]: string
}

export const MODULE_TYPE_OPTIONS = [
  { label: 'Contact Module', value: 'ContactModule' },
  { label: 'Form Module', value: 'FormModule' },
  { label: 'Hero Module', value: 'HeroModule' },
  { label: 'Highlights Module', value: 'HighlightsModule' },
  { label: 'Image Module', value: 'ImageModule' },
  { label: 'Main Hero Module', value: 'MainHeroModule' },
  { label: 'Navigation Module', value: 'NavigationModule' },
  { label: 'Next Item Module', value: 'NextItemModule' },
  { label: 'Portfolio Highlights Module', value: 'PortfolioHighlightsModule' },
  { label: 'Portfolio Timeline Module', value: 'PortfolioTimelineModule' },
  { label: 'Social Module', value: 'SocialModule' },
  { label: 'Text', value: 'TextModule' },
  { label: 'Title Text and Button Module', value: 'TextAndButtonModule' },
  { label: 'Youtube Embed Module', value: 'YoutubeEmbedModule' },
  { label: 'SCAN Contact Module', value: 'ScanContactModule' },
  { label: 'SCAN Hero Module', value: 'ScanHeroModule' },
  { label: 'SCAN Image Compare Module', value: 'ScanImageCompareModule' },
  { label: 'SCAN Partners Module', value: 'ScanPartnersModule' },
  { label: 'SCAN Projects Module', value: 'ScanProjectsModule' },
  { label: 'SCAN Text Image Module', value: 'ScanTextImageModule' },
]

export default {
  ContactModule,
  FormModule,
  HeroModule,
  HighlightsModule,
  ImageModule,
  MainHeroModule,
  NavigationModule,
  NextItemModule,
  PortfolioHighlightsModule,
  PortfolioTimelineModule,
  SocialModule,
  TextAndButtonModule,
  TextModule,
  YoutubeEmbedModule,
  // SCAN Modules
  ScanContactModule,
  ScanHeroModule,
  ScanImageCompareModule,
  ScanPartnersModule,
  ScanProjectsModule,
  ScanTextImageModule,
  // Module defaults
  Info,
}
