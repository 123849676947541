/* eslint-disable import/extensions */
import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { Route, Switch } from 'react-router'
import { USER_ME_QUERY } from './graphql/user/queries/user'
import { Login, Sidebar, Footer, ErrorHandler, PopupProvider, NotificationProvider } from './components'
import { IUser, IAppPolicy } from './interfaces/user/user.interface'
import { UserContext } from './contexts/user.context'
import { DarkModeContext } from './contexts/darkmode.context'
import { WHITELISTED_ROLES, RESOURCE } from './config/variables.config'
import GeneralDetails from './pages/general/general-details'
import { AppFrame, Content } from './layout/appframe'
import PageList from './pages/page/page-list'
import PageDetails from './pages/page/page-details'
import HomePage from './pages/home'
// import DictionaryDetails from './pages/dictionary/dictionary-details'
import DictionaryList from './pages/dictionary/dictionary-list'
import MenuListPage from './pages/menu/menu-list'
import MenuDetailsPage from './pages/menu/menu-details'
import BlogCategoryListPage from './pages/blog-category/blog-category-list'
import BlogCategoryDetailsPage from './pages/blog-category/blog-category-details'
import { GlobalStyle } from './layout/global.styles'
import { DARK_MODE } from './config/layout.config'
import DictionaryDetails from './pages/dictionary/dictionary-details'
import { Wrapper, Heading, Text, Container, Grid } from './layout'
import BlogPostListPage from './pages/blog-post/blog-post-list'
import BlogPostDetailsPage from './pages/blog-post/blog-post-details/blog-post-details'
import TeamMemberDetailsPage from './pages/team-member/team-member-details/team-member-details'
import TeamMemberListPage from './pages/team-member/team-member-list'
import PortfolioItemListPage from './pages/portfolio-item/portfolio-item-list'
import PortfolioItemDetailsPage from './pages/portfolio-item/portfolio-item-details'
import MediaPage from './pages/media'
import FormSubmissionDetails from './pages/form-submission/form-submission-details'
import FormSubmissionList from './pages/form-submission/form-submission-list'
import FormDetails from './pages/form/form-details'
import FormList from './pages/form/form-list'
import ScanProjectListPage from './pages/scan-project/scan-project-list'
import ScanProjectDetailsPage from './pages/scan-project/scan-project-details'

const App = () => {
  const { error, data } = useQuery(USER_ME_QUERY)
  const [isOpen, setIsOpen] = useState(true)
  if (error) return <Login />
  if (!data) return null

  const { userMe } = data as { userMe: IUser }
  if (!userMe || !userMe.email) return <Login />

  const { app_policy: appPolicy } = userMe

  const policy = appPolicy.find(({ resource }) => resource === RESOURCE)
  const { role } = policy as IAppPolicy

  if (!WHITELISTED_ROLES.includes(role)) {
    return (
      <Wrapper>
        <Container maxWidth={400}>
          <Grid xxsCol="auto" alignItems="center" alignContent="center" height="100vh">
            <Heading>Thanks for signing up!</Heading>
            <Text>
              Currently you don&apos;t have permission yet. Please contact the development team who can grant you
              access.
            </Text>
          </Grid>
        </Container>
      </Wrapper>
    )
  }

  return (
    <UserContext.Provider value={userMe}>
      <DarkModeContext.Provider value={{ dark: DARK_MODE }}>
        <AppFrame dark={DARK_MODE}>
          <NotificationProvider>
            <PopupProvider>
              <ErrorHandler />
              <GlobalStyle />
              <Sidebar isOpen={isOpen} setSideBar={(val: boolean) => setIsOpen(val)} user={userMe} />
              <Content isOpen={isOpen} dark={DARK_MODE}>
                <Switch>
                  <Route path="/general/:locale" component={GeneralDetails} />
                  <Route path="/dictionary" component={DictionaryList} />
                  <Route path="/translation/:id/:locale" component={DictionaryDetails} />
                  <Route path="/pages" component={PageList} />
                  <Route path="/page/:id/:locale" component={PageDetails} />
                  <Route path="/menus" component={MenuListPage} />
                  <Route path="/menu/:slug/:locale" component={MenuDetailsPage} />
                  <Route path="/blog-categories" component={BlogCategoryListPage} />
                  <Route path="/blog-category/:slug/:locale" component={BlogCategoryDetailsPage} />
                  <Route path="/blog-posts" component={BlogPostListPage} />
                  <Route path="/form-submission/:id" component={FormSubmissionDetails} />
                  <Route path="/form-submissions" component={FormSubmissionList} />
                  <Route path="/form/:slug/:locale" component={FormDetails} />
                  <Route path="/forms" component={FormList} />
                  <Route path="/media/*" component={MediaPage} />
                  <Route path="/media" component={MediaPage} />
                  <Route path="/blog-post/:slug/:locale" component={BlogPostDetailsPage} />
                  <Route path="/team-members" component={TeamMemberListPage} />
                  <Route path="/portfolio-items" component={PortfolioItemListPage} />
                  <Route path="/scan-projects" component={ScanProjectListPage} />
                  <Route path="/team-member/:slug/:locale" component={TeamMemberDetailsPage} />
                  <Route path="/portfolio-item/:slug/:locale" component={PortfolioItemDetailsPage} />
                  <Route path="/scan-project/:slug/:locale" component={ScanProjectDetailsPage} />
                  <Route path="/" component={HomePage} />
                </Switch>
              </Content>
              <Footer />
            </PopupProvider>
          </NotificationProvider>
        </AppFrame>
      </DarkModeContext.Provider>
    </UserContext.Provider>
  )
}

export default App
