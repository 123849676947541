/**
 * general
 */
export const DEFAULT_LOCALE = 'EN'
export const DEFAULT_STATUS = 'DRAFT'
export const LOCALES = ['EN', 'SPECTRAL_SCAN']
/**
 * auth
 */
export const AUTH_HEADER = 'Aerylabs-Auth'
export const AUTH_COOKIE = 'authorization'
export const RESOURCE = 'SPECTRAL_SERVER'
export const WHITELISTED_ROLES = ['ADMIN']
/**
 * Graphql
 */
export const SERVER_URI = 'https://api.spectral.hu'
/**
 * Media Library
 */
export const MEDIA_LIBRARY_IMAGE_WHITELIST = ['jpg', 'jpeg', 'JPG', 'JPEG', 'png', 'PNG', 'gif', 'GIF']
export const MEDIA_LIBRARY_WHITELIST = [...MEDIA_LIBRARY_IMAGE_WHITELIST, 'PDF', 'pdf', 'mp4']
export const MEDIA_LIBRARY_MIME_WHITELIST = ['image/jpeg', 'image/png', 'video/mp4']
export const MEDIA_LIBRARY_UPLOAD_LIMIT = 1048570000
