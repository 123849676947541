import styled from 'styled-components/macro'
import rem from '../../helpers/rem.helper'
import { fonts, colors } from '../variables'
import { WINDOW_BORDER_RADIUS, MAIN_BRAND_COLOR, BUTTON_BORDER_RADIUS } from '../../config/layout.config'

export const SectionWrapper = styled.div`
  box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
  border-radius: ${WINDOW_BORDER_RADIUS};
  margin-bottom: ${rem(18)};
  background-color: white;
  padding: ${rem(15)};
  display: grid;
  width: 100%;
`

export const SectionHeader = styled('div')<{ page?: boolean; dark?: boolean }>`
  background-color: ${({ dark }) => (dark ? colors.darkMidGrey : colors.lightGrey)};
  margin: ${rem(-15)} ${rem(-15)} ${rem(35)} ${rem(-15)};
  border-top-right-radius: ${WINDOW_BORDER_RADIUS};
  border-top-left-radius: ${WINDOW_BORDER_RADIUS};
  padding: ${rem(12)} ${rem(15)};
  color: ${MAIN_BRAND_COLOR};
  font-family: ${fonts.osc};
  max-height: ${rem(51)};
  width: 100%;

  ${({ page }) =>
    page &&
    `
    margin: ${rem(12)} ${rem(-15)} ${rem(25)} ${rem(-15)};
    background-color: transparent;
    width: calc(100% - 385px);
    text-transform: uppercase;
    box-sizing: border-box;
  `}
`

export const SectionContent = styled.div`
  button {
    border-radius: ${BUTTON_BORDER_RADIUS};
    padding: ${rem(7)};
    height: ${rem(41)};
    width: 100%;
  }
`

export const ActionCenter = styled('div')<{ top?: boolean; bottom?: boolean; popup?: boolean }>`
  transition: all 0.2s ease-in-out;
  backface-visibility: hidden;
  position: absolute;
  z-index: 2;
  right: 32px;
  ${({ top }) =>
    top &&
    `
    top: 0;
    margin-top: 20px;
    `}
  ${({ bottom }) =>
    bottom &&
    `
    bottom: 0;
    margin-bottom: -100px;
    `}
  ${({ popup }) =>
    popup &&
    `
    z-index: 0;
    margin-top: 35px;
    position: inherit;
    display: inline-block;
    margin-left: 32%;
    `}
`

export const ButtonWrapper = styled('div')<{ buttons: string }>`
  ${({ buttons }) =>
    buttons &&
    `
  grid-template-columns: repeat(${buttons}, 1fr);
 
`}
  padding-bottom: ${rem(5)};
  justify-items: center;
  align-items: center;
  grid-gap: ${rem(10)};
  text-align: center;
  margin-top: -5px;
  display: grid;
`
