/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect, useRef, useContext } from 'react'
import { isEqual } from 'lodash'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import DarkModeContext from '../../../contexts/darkmode.context'
import { Grid, Text, FlexItem } from '../../../layout'
import { IInputProps } from '../../form.provider'
import { Label } from '../input/input.styles'
import { WysiwygWrapper } from './wysiwyg.styles'

const WysiwygField: React.FC<IInputProps> = props => {
  const [snapshot, setSnapshot] = useState<any>()
  const [newValue, setNewValue] = useState<any>()
  const { setValue, value, fieldInfo, moduleInput } = props
  const { name, config } = fieldInfo
  const { flex, alternative } = config
  const { required, label, placeholder, validationError } = config
  const DarkModeCtx = useContext(DarkModeContext)
  const { dark } = DarkModeCtx

  useEffect(() => {
    let editorState
    if (!value) editorState = EditorState.createEmpty()
    if (value) {
      const contentBlock = htmlToDraft(value)
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      editorState = EditorState.createWithContent(contentState)
    }

    if (!isEqual(snapshot, editorState)) setSnapshot(editorState)
    if (!isEqual(newValue, value)) setNewValue(value)
  }, [value])

  useEffect(() => {
    if ((newValue || newValue === '' || newValue === 0) && !isEqual(newValue, value)) {
      // setValue({ [name as string]: newValue.replace(/style=/g, 'data-inline-style=') })
      setValue({ [name as string]: newValue })
    }
  }, [newValue])

  const onChange = (data: any) => {
    setSnapshot(data)
  }

  const onBlurChange = () => {
    const raw = convertToRaw(snapshot.getCurrentContent())
    setNewValue(draftToHtml(raw))
  }

  return (
    <FlexItem flex={flex || '100%'} margin="0 20px 10px 0">
      <WysiwygWrapper>
        <Grid alignItems="start" alignContent="start">
          <Label className="wysiwyg-label" onBlur={onBlurChange} htmlFor={name} dark={dark} alternative={alternative}>
            {label}
          </Label>
          <Editor
            onBlur={onBlurChange}
            editorState={snapshot}
            wrapperClassName={`aerylabs-wrapper ${alternative ? 'dark-theme' : ''}`}
            editorClassName="aerylabs-editor"
            onEditorStateChange={onChange}
            toolbar={{
              options: [
                'inline',
                'blockType',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'embedded',
                'emoji',
                'image',
                'remove',
                'history',
              ],
            }}
          />
          <input
            style={{ opacity: 0, height: '0px' }}
            autoComplete="off"
            required={required}
            value={newValue}
            tabIndex={-1}
            name={name}
          />
          {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
          {/* <textarea disabled value={newValue} /> */}
        </Grid>
      </WysiwygWrapper>
    </FlexItem>
  )
}

export default WysiwygField
