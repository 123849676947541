/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { PageHeader, FlexItem, Grid } from '../../layout'
import { SectionWrapper, SectionHeader } from '../../layout/section/section.styles'
import MediaList from '../../components/media/media-list'

const MediaPage = () => {
  // const { 0: qFolder = '/' } = useParams<{ 0: string }>( )

  return (
    <div>
      <PageHeader title="Media Library [beta]" withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark>Browse media</SectionHeader>
            <MediaList onSelect={() => null} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default MediaPage
