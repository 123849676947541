import { gql } from 'apollo-boost'

export const DELETE_MEDIA_MUTATION = gql`
  mutation DeleteMedia($key: String!) {
    deleteMedia(key: $key) {
      deleted
    }
  }
`

export const CREATE_MEDIA_FOLDER_MUTATION = gql`
  mutation CreateMediaFolder($folder: String!) {
    createMediaFolder(folder: $folder) {
      created
    }
  }
`
