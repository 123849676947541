/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react'
import { useMutation } from 'react-apollo'
import { QueryLazyOptions } from '@apollo/react-hooks'
import { CONFIRM_POPUP } from '../popup/popup.map'
import PopupContext from '../../contexts/popup.context'
import { CLONE_PAGE_MUTATION } from '../../graphql/page/mutations/page'
import NotificationContext from '../../contexts/notification.context'
import { CloneButton } from './clone-page.styles'
import Icon from '../../layout/icons/icons.styles'
import { IPageListQuery } from '../../pages/page/page-list/page-list'

interface IClonePage {
  id: string
  refreshList: (options?: QueryLazyOptions<IPageListQuery> | undefined) => void
}

const ClonePage: React.FC<IClonePage> = (props) => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const { id, refreshList } = props

  /**
   * MUTATIONS
   */
  const [clonePage, { error: clonePageMutationError, data: clonePageMutationData }] = useMutation(CLONE_PAGE_MUTATION)

  useEffect(() => {
    if (!clonePageMutationError && clonePageMutationData && clonePageMutationData.clonePage) {
      refreshList()
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Successfully cloned page!' })
    }
  }, [clonePageMutationData])

  const onClickClone = () => {
    popupCtx.show({
      id: 'clone-page',
      type: CONFIRM_POPUP,
      options: { message: 'Are you sure?' },
      submit: () => {
        // create tag
        const tag = `${Math.floor(Math.random() * 100)}`

        // clone page
        clonePage({ variables: { id, tag } })
      },
    })
  }

  return (
    <CloneButton type="button" onClick={() => onClickClone()}>
      <Icon size={18} aria-hidden="true">
        content_copy
      </Icon>
    </CloneButton>
  )
}

export default ClonePage
