import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useQuery, useMutation } from 'react-apollo'
import { dataFormatter } from '../../../helpers/data-formatter'
import { NotificationContext } from '../../../contexts/notification.context'
import { LOCALES } from '../../../config/variables.config'
import NotFound from '../../../components/not-found'
import FormProvider from '../../../form2/form.provider'
import { BLOG_CATEGORY_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { LOCALE_OPTIONS, STATUS_OPTIONS } from '../../../config/select-options.config'
import { PageHeader, FlexItem } from '../../../layout'
import { IBlogCategoryMutation } from '../../../interfaces/blog-category/blog-category.interface'
import { BLOG_CATEGORY_QUERY } from '../../../graphql/blog-category/queries/blog-category'
import {
  UPDATE_BLOG_CATEGORY_MUTATION,
  DELETE_BLOG_CATEGORY_MUTATION,
} from '../../../graphql/blog-category/mutations/blog-category'

const BlogCategoryDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { slug: qSlug, locale: qLocale } = useParams() as { slug: string; locale: string }
  const localeUC = qLocale ? qLocale.toUpperCase() : ''
  const [itemID, setItemID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IBlogCategoryMutation>()

  /**
   * QUERY
   */
  const { error: queryError, data: queryData } = useQuery(BLOG_CATEGORY_QUERY, {
    variables: { slug: qSlug, locale: localeUC },
  })
  useEffect(() => {
    if (queryData && queryData.blogCategory) {
      const { blogCategory } = queryData
      const fd = dataFormatter(blogCategory, {})
      const { id, ...formattedData } = fd
      setItemID(id)
      setSnapshot(formattedData as IBlogCategoryMutation)
    }
  }, [queryData])

  /**
   * MUTATIONS
   */
  const [updateBlogCategory, { error: updateError, data: updateData }] = useMutation(UPDATE_BLOG_CATEGORY_MUTATION)
  const [deleteBlogCategory, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_BLOG_CATEGORY_MUTATION,
  )
  useEffect(() => {
    if (!updateError && updateData && updateData.updateBlogCategory) {
      const { updateBlogCategory: updateBlogCategoryData } = updateData
      const fd = dataFormatter(updateBlogCategoryData, {})
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Blog Category Updated!' })
    }
  }, [updateData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting blog category!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Blog Category Deleted!' })
      setTimeout(() => {
        window.location.href = `/blog-categories`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  /**
   * ONEVENT FUNCTIONS
   */
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateBlogCategory({ variables: { id: itemID, data: newData } })
  }

  const onDelete = () => {
    deleteBlogCategory({ variables: { id: itemID } })
  }

  /**
   * COMPONENT LOGIC
   */
  if (!LOCALES.includes(localeUC)) return <NotFound />
  if (queryError) return <div>error</div>
  if (!snapShot) return null

  const { blogCategory } = queryData
  const { name, locale } = blogCategory
  const { slug } = snapShot

  return (
    <div>
      <PageHeader title={`Blog Category Details - ${name} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onDelete={onDelete}
            deleteVerifier={slug}
            onSubmit={onUpdate}
            schema={BLOG_CATEGORY_UPDATE_SCHEMA}
            variables={{ LOCALE_OPTIONS, STATUS_OPTIONS }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default BlogCategoryDetailsPage
