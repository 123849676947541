/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const PAGE_QUERY = gql`
  query Page($locale: Locale!, $id: ObjectID!) {
    page(where: { locale: $locale, id: $id }) {
      id
      locale
      status
      alias
      title
      slug
      seo {
        title
        description
        keywords
        image
      }
      modules {
        id
        type
        info
        data
      }
    }
    forms {
      id
      alias
    }
  }
`

export const PAGES_QUERY = gql`
  query Pages($locale: Locale, $search: String, $orderBy: PageOrderByInput, $skip: Int, $limit: Int, $status: Status) {
    pages(
      where: { locale: $locale, status: $status }
      searchInAliasAndTitleAndSlug: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      title
      id
      locale
      status
      slug
      alias
      updated_at
    }
    pageCount(where: { locale: $locale, status: $status }, searchInAliasAndTitleAndSlug: $search)
  }
`
