import React from 'react'
import { useCookies } from 'react-cookie'
import { SERVER_URI, AUTH_COOKIE } from '../../config/variables.config'
import { Button, Heading, colors, Text, Grid } from '../../layout'
import { LoginWrapper, LoginWindow, LoginPrompt } from './login.styles'
import { LOGIN_BRAND_IMAGE } from '../../config/layout.config'

const Login = () => {
  const [, setCookie] = useCookies([AUTH_COOKIE])

  const onLogin = () => {
    window.location.href = `${SERVER_URI}/auth/google/login`
  }

  const getDomain = () => {
    // if localhost -> localhost
    // if sub.sub.sub.domain.com -> .domain.com
    const { host } = window.location
    if (host.includes('localhost')) return `localhost`
    return `.${host
      .split('.')
      .slice(-2)
      .join('.')}`
  }

  const query = window.location.search

  let error = false
  if (query.includes('?state')) {
    fetch(`${SERVER_URI}/auth/google/callback${query}`)
      .then(response => response.json())
      .then(data => {
        const { Token } = data
        if (!Token) {
          error = true
          return
        }
        setCookie(AUTH_COOKIE, Token, { path: '/', domain: getDomain() })
        window.location.reload()
      })
      .catch(() => {
        error = true
      })
  }

  if (error) {
    // TODO
  }

  return (
    <LoginWindow backgroundImage={LOGIN_BRAND_IMAGE}>
      <LoginWrapper>
        <LoginPrompt>
          <Grid>
            <Heading page textColor={colors.lightGrey}>
              Sign In
            </Heading>
            <Text medium textColor={colors.lightGrey}>
              Please log in using one of these services:
            </Text>
          </Grid>
          <Button onClick={onLogin}>Sign in with Google</Button>
        </LoginPrompt>
      </LoginWrapper>
    </LoginWindow>
  )
}

export default Login
