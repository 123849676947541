/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_SCAN_PROJECT_MUTATION = gql`
  mutation CreateScanProject($data: ScanProjectCreateInput!) {
    createScanProject(data: $data) {
      description
      locale
      status
      title
      slug
      date
      id
    }
  }
`

export const UPDATE_SCAN_PROJECT_MUTATION = gql`
  mutation UpdateScanProject($id: ObjectID!, $data: ScanProjectUpdateInput!) {
    updateScanProject(where: { id: $id }, data: $data) {
      id
      locale
      status
      title
      slug
      client
      trailer
      date
      description
      featured_image
      thumbnail_image
      partner_logo
      members {
        key
        value
      }
      modules {
        type
        info
        data
        id
      }
    }
  }
`

export const DELETE_SCAN_PROJECT_MUTATION = gql`
  mutation DeleteScanProject($id: ObjectID!) {
    deleteScanProject(where: { id: $id }) {
      id
    }
  }
`
