/* eslint-disable import/extensions */
import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useParams } from 'react-router'
import { dataFormatter } from '../../../helpers/data-formatter'
import FormProvider from '../../../form2/form.provider'
import { TEAM_MEMBER_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { STATUS_OPTIONS, SPECTRAL_LOCALE_OPTIONS } from '../../../config/select-options.config'
import { PageHeader, FlexItem } from '../../../layout'
import { NotificationContext } from '../../../contexts/notification.context'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { TEAM_MEMBER_QUERY } from '../../../graphql/team-member/queries/team-member'
import { ITeamMember } from '../../../interfaces/team-member/team-member.interface'
import {
  UPDATE_TEAM_MEMBER_MUTATION,
  DELETE_TEAM_MEMBER_MUTATION,
} from '../../../graphql/team-member/mutations/team-member'

const TeamMemberDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { locale: qLocale, slug: qSlug } = useParams() as { locale: string; slug: string }
  const capsLocale = qLocale.toUpperCase()
  const [team_memberID, setTeamMemberID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<ITeamMember>()
  const [portfolioItemsOptions, setPortfolioItemsOptions] = useState<IOption[]>([])

  // QUERY
  const { error: queryError, data: queryData } = useQuery(TEAM_MEMBER_QUERY, {
    variables: { locale: capsLocale, slug: qSlug },
  })

  useEffect(() => {
    if (queryData && queryData.teamMember) {
      const { teamMember } = queryData
      const fd = dataFormatter(teamMember, {
        populatedFields: ['portfolio_items'],
        jsonFields: ['info', 'data'],
      })
      const { id, ...formattedData } = fd
      setTeamMemberID(id)
      setSnapshot(formattedData as ITeamMember)

      // Portfolio Items
      if (queryData.portfolio_items && queryData.portfolio_items.length) {
        setPortfolioItemsOptions(
          queryData.portfolio_items.map(({ id: cId, name: cName }: { id: string; name: string }) => ({
            label: cName,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  // MUTATIONS
  const [updateTeamMember, { error: mutationError, data: mutationData }] = useMutation(UPDATE_TEAM_MEMBER_MUTATION)
  const [deleteTeamMember, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_TEAM_MEMBER_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateTeamMember) {
      const { updateTeamMember: updateTeamMemberData } = mutationData
      const fd = dataFormatter(updateTeamMemberData, {
        populatedFields: ['portfolio_items'],
        jsonFields: ['info', 'data'],
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Team Member Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting team member!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Team Member Deleted!' })
      setTimeout(() => {
        window.location.href = `/team-members`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  // ONEVENT FUNCTIONS
  // eslint-disable-next-line
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateTeamMember({ variables: { id: team_memberID, data: newData } })
  }

  const onDelete = () => {
    deleteTeamMember({ variables: { id: team_memberID } })
  }

  // COMPONENT LOGIC
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { teamMember } = queryData
  const { locale, name, slug } = teamMember as ITeamMember

  return (
    <div>
      <PageHeader title={`Team Member Details - ${name} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onSubmit={onUpdate}
            onDelete={onDelete}
            deleteVerifier={slug}
            schema={TEAM_MEMBER_UPDATE_SCHEMA}
            variables={{
              STATUS_OPTIONS,
              SPECTRAL_LOCALE_OPTIONS,
              PORTFOLIO_ITEM_OPTIONS: portfolioItemsOptions,
            }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default TeamMemberDetailsPage
