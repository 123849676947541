/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const PORTFOLIO_ITEM_QUERY = gql`
  query portfolioItem($locale: Locale!, $slug: String!) {
    portfolioItem(where: { locale: $locale, slug: $slug }) {
      featured_image
      thumbnail_image
      description
      locale
      status
      title
      slug
      date
      id
      team_members {
        position
        locale
        status
        email
        phone
        name
        slug
        id
      }
      modules {
        type
        info
        data
        id
      }
    }
    teamMembers(where: { status: ACTIVE }) {
      id
      name
    }
    forms {
      id
      alias
    }
  }
`

export const PORTFOLIO_ITEMS_QUERY = gql`
  query portfolioItems(
    $status: Status
    $locale: Locale
    $search: String
    $orderBy: PortfolioItemOrderByInput
    $skip: Int
    $limit: Int
  ) {
    portfolioItems(
      where: { locale: $locale, status: $status }
      searchInTitleAndSlug: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      featured_image
      thumbnail_image
      description
      created_at
      updated_at
      locale
      status
      title
      slug
      date
      id
      team_members {
        position
        locale
        status
        email
        phone
        name
        slug
        id
      }
      modules {
        type
        info
        data
        id
      }
    }

    portfolioItemCount(where: { locale: $locale, status: $status }, searchInTitleAndSlug: $search)
  }
`
