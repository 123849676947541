export const LOCALE_OPTIONS = [
  { label: 'Spectral.hu', value: 'EN' },
  { label: 'Spectral SCAN', value: 'SPECTRAL_SCAN' },
]

export const SCAN_LOCALE_OPTIONS = [{ label: 'Spectral SCAN', value: 'SPECTRAL_SCAN' }]

export const SPECTRAL_LOCALE_OPTIONS = [{ label: 'Spectral.hu', value: 'EN' }]

export const LINK_TYPE_OPTIONS = [
  { label: 'INNER', value: 'INNER' },
  { label: 'OUTER', value: 'OUTER' },
]
export const GENERAL_NAVIGATION_TYPE_OPTIONS = [
  { label: 'HEADER', value: 'HEADER' },
  { label: 'FOOTER', value: 'FOOTER' },
]
export const GENERAL_INFO_TYPE_OPTIONS = [
  { label: 'LOGO', value: 'LOGO' },
  { label: 'EMAIL', value: 'EMAIL' },
  { label: 'PHONE', value: 'PHONE' },
  { label: 'PHONE2', value: 'PHONE2' },
  { label: 'ADDRESS', value: 'ADDRESS' },
  { label: 'LANGUAGE_SELECTOR', value: 'LANGUAGE_SELECTOR' },
  { label: 'COPYRIGHT', value: 'COPYRIGHT' },
  { label: 'FAVICON', value: 'FAVICON' },
  { label: 'COMPANY_REGISTRATION_NUM', value: 'COMPANY_REGISTRATION_NUM' },
  { label: 'TAX_NUMBER', value: 'TAX_NUMBER' },
]
export const GENERAL_SOCIAL_TYPE_OPTIONS = [
  { label: 'FACEBOOK', value: 'FACEBOOK' },
  { label: 'INSTAGRAM', value: 'INSTAGRAM' },
  { label: 'LINKEDIN', value: 'LINKEDIN' },
  { label: 'TWITTER', value: 'TWITTER' },
]
export const STATUS_OPTIONS = [
  { label: 'DRAFT', value: 'DRAFT' },
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'ARCHIVE', value: 'ARCHIVE' },
]
export const PAGES_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'alias_ASC', value: 'alias_ASC' },
  { label: 'alias_DESC', value: 'alias_DESC' },
  { label: 'title_ASC', value: 'title_ASC' },
  { label: 'title_DESC', value: 'title_DESC' },
  { label: 'slug_ASC', value: 'slug_ASC' },
  { label: 'slug_DESC', value: 'slug_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]

export const TRANSLATIONS_ORDER_BY_OPTIONS = [
  { label: 'Locale ASC', value: 'locale_ASC' },
  { label: 'Locale DESC', value: 'locale_DESC' },
  { label: 'Key ASC', value: 'key_ASC' },
  { label: 'Key DESC', value: 'key_DESC' },
  { label: 'Value ASC', value: 'value_ASC' },
  { label: 'Value DESC', value: 'value_DESC' },
  { label: 'Created at ASC', value: 'created_at_ASC' },
  { label: 'Created at DESC', value: 'created_at_DESC' },
  { label: 'Updated at ASC', value: 'updated_at_ASC' },
  { label: 'Updated at DESC', value: 'updated_at_DESC' },
]
export const MENUS_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'name_ASC', value: 'name_ASC' },
  { label: 'name_DESC', value: 'name_DESC' },
  { label: 'slug_ASC', value: 'slug_ASC' },
  { label: 'slug_DESC', value: 'slug_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]
export const BLOG_CATEGORIES_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'name_ASC', value: 'name_ASC' },
  { label: 'name_DESC', value: 'name_DESC' },
  { label: 'slug_ASC', value: 'slug_ASC' },
  { label: 'slug_DESC', value: 'slug_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]
export const BLOG_POSTS_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'title_ASC', value: 'title_ASC' },
  { label: 'title_DESC', value: 'title_DESC' },
  { label: 'slug_ASC', value: 'slug_ASC' },
  { label: 'slug_DESC', value: 'slug_DESC' },
  { label: 'category_ASC', value: 'category_ASC' },
  { label: 'category_DESC', value: 'category_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]
export const TEAM_MEMBERS_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'name_ASC', value: 'name_ASC' },
  { label: 'name_DESC', value: 'name_DESC' },
  { label: 'slug_ASC', value: 'slug_ASC' },
  { label: 'slug_DESC', value: 'slug_DESC' },
  { label: 'email_ASC', value: 'email_ASC' },
  { label: 'email_DESC', value: 'email_DESC' },
  { label: 'position_ASC', value: 'position_ASC' },
  { label: 'position_DESC', value: 'position_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]
export const PORTFOLIO_ITEMS_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'title_ASC', value: 'title_ASC' },
  { label: 'title_DESC', value: 'title_DESC' },
  { label: 'slug_ASC', value: 'slug_ASC' },
  { label: 'slug_DESC', value: 'slug_DESC' },
  { label: 'date_ASC', value: 'date_ASC' },
  { label: 'date_DESC', value: 'date_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]
export const SCAN_PROJECTS_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'title_ASC', value: 'title_ASC' },
  { label: 'title_DESC', value: 'title_DESC' },
  { label: 'slug_ASC', value: 'slug_ASC' },
  { label: 'slug_DESC', value: 'slug_DESC' },
  { label: 'date_ASC', value: 'date_ASC' },
  { label: 'date_DESC', value: 'date_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]
export const FORM_SUBMISSION_STATUS_OPTIONS = [
  { label: 'NEW', value: 'NEW' },
  { label: 'PENDING', value: 'PENDING' },
  { label: 'DONE', value: 'DONE' },
]
export const FORMS_SUBMISSION_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'email_ASC', value: 'email_ASC' },
  { label: 'email_DESC', value: 'email_DESC' },
  { label: 'form_ASC', value: 'form_ASC' },
  { label: 'form_DESC', value: 'form_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]
export const FORMS_ORDER_BY_OPTIONS = [
  { label: 'locale_ASC', value: 'locale_ASC' },
  { label: 'locale_DESC', value: 'locale_DESC' },
  { label: 'status_ASC', value: 'status_ASC' },
  { label: 'status_DESC', value: 'status_DESC' },
  { label: 'alias_ASC', value: 'alias_ASC' },
  { label: 'alias_DESC', value: 'alias_DESC' },
  { label: 'title_ASC', value: 'title_ASC' },
  { label: 'title_DESC', value: 'title_DESC' },
  { label: 'slug_ASC', value: 'slug_ASC' },
  { label: 'slug_DESC', value: 'slug_DESC' },
  { label: 'created_at_ASC', value: 'created_at_ASC' },
  { label: 'created_at_DESC', value: 'created_at_DESC' },
  { label: 'updated_at_ASC', value: 'updated_at_ASC' },
  { label: 'updated_at_DESC', value: 'updated_at_DESC' },
]
export const FORM_FIELDS_OPTIONS = [
  { label: 'TEXT', value: 'TEXT' },
  { label: 'TEXTAREA', value: 'TEXTAREA' },
  { label: 'SELECT', value: 'SELECT' },
  { label: 'MULTISELECT', value: 'MULTISELECT' },
  { label: 'CHECKBOX', value: 'CHECKBOX' },
  { label: 'EMAIL', value: 'EMAIL' },
  { label: 'PHONE', value: 'PHONE' },
  { label: 'NUMBER', value: 'NUMBER' },
  { label: 'DESCRIPTIVE', value: 'DESCRIPTIVE' },
]
export const LIMIT_OPTIONS = [
  // { label: '1', value: '1' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
  { label: '200', value: '200' },
]

export const LAYOUT_OPTIONS = [
  { label: 'select...', value: null },
  { label: 'Blog', value: 'blog' },
  { label: 'Page', value: 'page' },
]
