/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  PreviewWrapper,
  PopupWrapper,
  ImagePreview,
  CloseButton,
  Attribute,
  InfoValue,
  Backdrop,
  Heading,
} from './media-details.styles'
import { MEDIA_DETAILS_POPUP } from '../../../popup.map'
import { Button, fonts, Grid } from '../../../../../layout'
import Icon from '../../../../../layout/icons/icons.styles'
import { fileSize } from '../../../../../helpers/filesize.helper'
import NotificationContext from '../../../../../contexts/notification.context'
import { fileTypesIcons } from '../../../../../layout/variables'

const MediaDetailsPopup: React.FC<any> = (props) => {
  const { id, type, submit, hide, options } = props
  const { data } = options
  const { medium, onDelete } = data
  const [copyStatus, setcopyStatus] = useState(false)
  const notificationCtx = useContext(NotificationContext)
  useEffect(() => {
    if (copyStatus) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'URL Copied!' })
    }
  }, [copyStatus])
  if (type !== MEDIA_DETAILS_POPUP) return <div>Invalid popup type!</div>

  const onConfirm = () => {
    submit()
    hide(id)
  }

  const onClose = () => {
    hide(id)
  }

  const copyStringToClipboard = (str: string) => {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)

    setcopyStatus(true)
    setTimeout(() => setcopyStatus(false), 1500)
  }
  const fileName = medium?.key?.split('/').pop()
  const fileExtension = medium?.key.split('.').pop().toLowerCase()

  return (
    <Backdrop>
      <PopupWrapper>
        <CloseButton onClick={onClose} type="button">
          <Icon>close</Icon>
        </CloseButton>
        <Grid xsCol="auto 1fr" gap="0px">
          <PreviewWrapper>
            {fileTypesIcons[fileExtension] === 'image' ? (
              <ImagePreview src={medium.url} alt="img" />
            ) : (
              <Icon size={400}>{fileTypesIcons[fileExtension]}</Icon>
            )}
          </PreviewWrapper>
          <Grid gap="50px" alignItems="center">
            <Heading>File Attributes</Heading>
            <Grid xsCol="auto 2fr" gap="20px" alignItems="center">
              <Attribute>Name: </Attribute>
              <InfoValue>{fileName}</InfoValue>
              <Attribute>Extension: </Attribute>
              <InfoValue>{fileExtension}</InfoValue>
              <Attribute>Size: </Attribute>
              <InfoValue>{fileSize(medium.size)}</InfoValue>
              <Attribute>Url: </Attribute>
              <InfoValue>{medium.url}</InfoValue>
              <Attribute>Last modified: </Attribute>
              <InfoValue>{dayjs(medium.last_modified).format('YYYY.MM.DD HH:mm:ss')}</InfoValue>
            </Grid>
            <Grid xsCol="auto auto auto" justifyItems="start" justifyContent="start" gap="20px">
              <Button
                ghost
                warning
                onClick={() => {
                  onDelete(medium.key)
                  hide(id)
                }}
              >
                Delete
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault()
                  copyStringToClipboard(medium.url)
                }}
              >
                Copy URL to Clipboard
              </Button>
              <Button
                style={{ textDecoration: 'none', fontFamily: fonts.osc }}
                target="_blank"
                as="a"
                secondary
                href={medium.url}
              >
                Open File
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PopupWrapper>
    </Backdrop>
  )
}

export default MediaDetailsPopup
