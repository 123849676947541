import React, { useContext, useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'querystring'
import { useLazyQuery } from 'react-apollo'
import dayjs from 'dayjs'
import { FORM_SUBMISSIONS_QUERY } from '../../../graphql/form-submission/queries/form-submission'
import { IFormSubmission } from '../../../interfaces/form-submission/form-submission-interface'
import { Table, Thead, Th, Tbody, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { usePushQuery } from '../../../helpers/query-string.helper'
import { FilterDropdown, Pagination } from '../../../components'
import DarkModeContext from '../../../contexts/darkmode.context'
import { IForm } from '../../../interfaces/form/form-interface'
import { removeFalsy } from '../../../helpers/object.helper'
import { PageHeader, FlexItem, Grid } from '../../../layout'
import {
  FORM_SUBMISSION_STATUS_OPTIONS,
  FORMS_SUBMISSION_ORDER_BY_OPTIONS,
  LOCALE_OPTIONS,
} from '../../../config/select-options.config'

interface IFormSubmissionListQuery {
  is?: string
  locale?: string
  status?: string
  email?: string
  form?: string
  search?: string
  orderBy?: string
  skip?: string
  limit?: string
}

const DEFAULT_LIMIT = 10
const DEFAULT_SKIP = 0
const DEFAULT_ORDER_BY = 'updated_at_DESC'

const FormSubmissionList = () => {
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const location = useLocation()
  const history = useHistory()
  const { search: locationSearch } = location
  const [query, setQuery] = useState<IFormSubmissionListQuery>({ limit: '10' })

  /**
   * QUERIES
   */
  const [getList, { error: queryError, data: queryData, loading }] = useLazyQuery(FORM_SUBMISSIONS_QUERY, {
    variables: query,
  })
  useEffect(() => {
    if (query) {
      const searchQuery = queryString.parse(locationSearch.substr(1))
      // set defaults
      const {
        orderBy = DEFAULT_ORDER_BY,
        limit = DEFAULT_LIMIT,
        skip = DEFAULT_SKIP,
      } = searchQuery as IFormSubmissionListQuery
      setQuery({ ...removeFalsy({ ...searchQuery, orderBy, limit }), skip })
      getList()
    }
  }, [locationSearch])
  /**
   * MUTATIONS
   */

  /**
   * POPUPS
   */

  /**
   * COMPONENT LOGIC
   */
  const [pushQuery] = usePushQuery(location, history)

  if (queryError) return <div>error</div>
  const { formSubmissionCount, formSubmissions: qFormSubmissions = [], forms: qForms = [] } =
    queryData || ({} as { formSubmissionCount: number; formSubmissions: IForm[] })

  const formSubmissions: IFormSubmission[] = qFormSubmissions as IFormSubmission[]

  return (
    <div>
      <PageHeader title="Form Submissions" withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark={dark}>Filter</SectionHeader>
            <Grid mdCol={2} gap="20px" marginBottom="35px">
              <FilterDropdown
                name="locale"
                label="Locale"
                value={query.locale ? { value: query.locale, label: query.locale } : undefined}
                options={LOCALE_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('locale', value)}
              />
              <FilterDropdown
                name="orderBy"
                label="Order By"
                value={query.orderBy ? { value: query.orderBy, label: query.orderBy } : undefined}
                options={FORMS_SUBMISSION_ORDER_BY_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('orderBy', value)}
              />
              <FilterDropdown
                name="status"
                label="Status"
                value={query.status ? { value: query.status, label: query.status } : undefined}
                options={FORM_SUBMISSION_STATUS_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('status', value)}
              />
              <FilterDropdown
                name="form"
                label="Form"
                value={query.form ? { value: query.form, label: query.form } : undefined}
                options={qForms.map(({ id, alias }: IForm) => ({ value: id, label: alias })) as [IOption]}
                onChange={(value) => pushQuery('form', value)}
              />
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Results</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Form</Th>
                  <Th>Email</Th>
                  <Th>Date</Th>
                  <Th>Status</Th>
                  <Th>Locale</Th>
                  <Th>id</Th>
                </Thead>
                <Tbody>
                  {loading && <p>Loading...</p>}
                  {!!formSubmissions.length &&
                    formSubmissions.map(({ id, email, locale, status, created_at, form }) => {
                      return (
                        <Tr key={id}>
                          <Td>{form && form.alias}</Td>
                          <Td>
                            <NavLink to={`/form-submission/${id}`}>{email}</NavLink>
                          </Td>
                          <Td>{dayjs(created_at).format('YYYY.MM.DD HH:mm:ss')}</Td>
                          <Td>{status}</Td>
                          <Td>{LOCALE_OPTIONS.find(({ value }) => value === locale)?.label}</Td>
                          <Td>{id}</Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </Grid>
            <Pagination totalCount={formSubmissionCount} pushQuery={pushQuery} query={query} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default FormSubmissionList
