/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from 'react'
import ReactSelect from 'react-select'
import { isEqual } from 'lodash'
import { IInputProps } from '../../form.provider'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { Grid, FlexItem } from '../../../layout'
import { Label } from '../input/input.styles'
import { selectStyle } from './select.styles'

const MultiselectField: React.FC<IInputProps> = (props) => {
  const [snapshot, setSnapshot] = useState<IOption[]>()
  const { setValue, value = [], fieldInfo } = props
  const { name, config } = fieldInfo
  const { flex } = config
  const { required, options, label } = config

  useEffect(() => {
    const defaultOption: IOption[] = []
    if (options) {
      const o: IOption[] = options as IOption[]
      o.map((option: IOption) => {
        const { value: oValue } = option
        if (value.includes(oValue)) defaultOption.push(option)
        return ''
      })
    }
    setSnapshot(defaultOption)
  }, [value, name])

  useEffect(() => {
    if (snapshot || snapshot === '') {
      const s: IOption[] = snapshot as IOption[]
      const newVals: string[] = s.map(({ value: v }) => v)
      if (!isEqual(newVals, value)) {
        setValue({ [name as string]: newVals })
      }
    }
  }, [snapshot])

  const onChange = (oValue: any) => {
    setSnapshot(oValue || [])
  }

  return (
    <FlexItem flex={flex || '100%'} margin="0 20px 10px 0">
      <Grid>
        {label && <Label>{label}</Label>}
        <ReactSelect
          options={options as [IOption]}
          styles={selectStyle}
          onChange={onChange}
          value={snapshot}
          name={name}
          isMulti
        />
        {required && (
          <input
            style={{
              position: 'absolute',
              opacity: 0,
              padding: 0,
              height: 0,
              margin: 0,
            }}
            required={required}
            autoComplete="off"
            tabIndex={-1}
            value={value}
          />
        )}
      </Grid>
    </FlexItem>
  )
}

export default MultiselectField
