import React, { useContext } from 'react'
import dayjs from 'dayjs'
import { PageHeader, FlexItem, Grid } from '../../layout'
import { UserContext } from '../../contexts/user.context'
import { SectionWrapper, SectionHeader } from '../../layout/section/section.styles'

const HomePage = () => {
  const userMe = useContext(UserContext)
  const { firstname } = userMe || {}
  // const actualMonth = dayjs().format('M')

  return (
    <div>
      <PageHeader title={`Greetings ${firstname}!`} withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark>Stats of {dayjs().format('MMMM')}</SectionHeader>
            <Grid mdCol={3} gap="20px" marginBottom="35px" />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default HomePage
