import { gql } from 'apollo-boost'

export const MEDIAS_QUERY = gql`
  query Medias($folder: String) {
    medias(where: { folder: $folder }) {
      url
      key
      size
      last_modified
      domain
    }
  }
`

export const MEDIA_SIGNED_URL_QUERY = gql`
  query MediaSignedURL($fileName: String!, $fileExtension: String!) {
    mediaSignedURL(fileName: $fileName, fileExtension: $fileExtension) {
      url
    }
  }
`
