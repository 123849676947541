import styled from 'styled-components/macro'
import { colors, fonts } from '../../../../layout'
import rem from '../../../../helpers/rem.helper'

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  color: ${colors.body};
  z-index: 9999999;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`

export const PopupWrapper = styled.div`
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  border-radius: ${rem(5)};
  margin: ${rem(50)} auto;
  max-width: ${rem(1000)};
  padding: ${rem(50)};
  z-index: 9999999;
`

export const CloseButton = styled.button`
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  font-size: ${rem(20)};
  padding: ${rem(15)};
  position: absolute;
  border: none;
  right: 0;
  top: 0;
  &:hover {
    color: ${colors.main};
  }
`

export const UploadTypeSelector = styled.div`
  text-align: center;
  padding-top: 50px;
  padding-bottom: 40px;
  border-right: 1px solid #e2e2e2;
  padding-right: 38px;
  margin-bottom: 60px;
`

export const UploadTypeSelectorItem = styled('div')<{ active?: boolean }>`
  font-family: ${fonts.osc};
  padding: 14px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 400;
  border-bottom: 1px solid #00000021;

  ${({ active }) =>
    active &&
    `
      color: ${colors.main}
    `}
`

export const UploadTypeBody = styled('div')`
  max-height: 400px;
  overflow: scroll;
`

export const UploadUrlInput = styled('input')`
  font-family: ${fonts.osc};
  padding: 10px 10px;
  border-radius: 3px;
  border: 1px solid #a9a9a9;
  outline: none;
  width: 80%;
`
export const UrlWrapper = styled('div')`
  width: 800px;
  padding: 68px 60px 0px 60px;

  p {
    font-family: ${fonts.osc};
    font-size: 15px;
    margin-bottom: 9px;
  }

  a {
    margin-top: 15px;
    font-family: ${fonts.osc};
    color: ${colors.midGrey};
    font-size: 14px;
    margin-bottom: 9px;
    padding: 15px 0px;
    text-decoration: none;
    transition: 0.5s;
    margin-right: 35px;

    i {
      margin-right: 10px;
      position: relative;
      top: 6px;
    }

    &:hover {
      color: ${colors.darkGrey};
    }
  }
`
