/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React from 'react'
import { CustomInput } from './date.styles'

interface IDateButton {
  onClick?: (e: any) => void
  value: string
  disabled?: boolean
  dark?: boolean
  alternative?: boolean
}

const DateButton = ({ onClick, value, disabled, dark, alternative }: IDateButton) => {
  return (
    <CustomInput type="button" onClick={onClick} disabled={disabled} dark={dark} alternative={alternative}>
      {value}
    </CustomInput>
  )
}

export default DateButton
