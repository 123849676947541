/* eslint-disable import/extensions */
import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useHistory } from 'react-router'
import queryString from 'querystring'
import { useLazyQuery, useMutation } from 'react-apollo'
import dayjs from 'dayjs'
import { usePushQuery } from '../../../helpers/query-string.helper'
import { removeFalsy } from '../../../helpers/object.helper'
import { Grid, PageHeader, FlexItem, Button } from '../../../layout'
import { FilterSearch, FilterDropdown, Pagination } from '../../../components'
import {
  SPECTRAL_LOCALE_OPTIONS,
  STATUS_OPTIONS,
  TEAM_MEMBERS_ORDER_BY_OPTIONS,
} from '../../../config/select-options.config'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { Table, Thead, Tbody, Th, Tr, Td, NavLink } from '../../../layout/table/table.styles'
import { SectionWrapper, SectionHeader } from '../../../layout/section/section.styles'
import PopupContext from '../../../contexts/popup.context'
import { FORM_POPUP } from '../../../components/popup/popup.map'
import { TEAM_MEMBER_CREATE_SCHEMA } from '../../../schemas/schema.map'
import { DEFAULT_LOCALE, DEFAULT_STATUS } from '../../../config/variables.config'
import NotificationContext from '../../../contexts/notification.context'
import DarkModeContext from '../../../contexts/darkmode.context'
import { ITeamMember } from '../../../interfaces/team-member/team-member.interface'
import { IPortfolioItemsOptions } from '../../../interfaces/portfolio-item/portfolio-item.interface'
import { TEAM_MEMBERS_QUERY } from '../../../graphql/team-member/queries/team-member'
import { CREATE_TEAM_MEMBER_MUTATION } from '../../../graphql/team-member/mutations/team-member'

interface ITeamMemberListQuery {
  locale?: string
  search?: string
  status?: string
  orderBy?: string
  skip?: string
  limit?: string
}

const DEFAULT_LIMIT = 10
const DEFAULT_SKIP = 0
const DEFAULT_ORDER_BY = 'created_at_DESC'

const TeamMemberListPage = () => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const darkModeCtx = useContext(DarkModeContext)
  const { dark } = darkModeCtx
  const location = useLocation()
  const history = useHistory()
  const { search: locationSearch } = location
  const [query, setQuery] = useState<ITeamMemberListQuery>({ limit: '10' })
  const [portfolioItemsOptions, setPortfolioItemsOptions] = useState<IOption[]>([])

  // QUERY
  const [getList, { error: queryError, data: queryData, loading }] = useLazyQuery(TEAM_MEMBERS_QUERY, {
    variables: query,
  })
  useEffect(() => {
    if (query) {
      const searchQuery = queryString.parse(locationSearch.substr(1))
      // set defaults
      const {
        orderBy = DEFAULT_ORDER_BY,
        limit = DEFAULT_LIMIT,
        skip = DEFAULT_SKIP,
      } = searchQuery as ITeamMemberListQuery
      setQuery({ ...removeFalsy({ ...searchQuery, orderBy, limit }), skip })
      getList()
    }
  }, [locationSearch])

  useEffect(() => {
    if (queryData && queryData.portfolio_items && queryData.portfolio_items.length) {
      setPortfolioItemsOptions(
        queryData.portfolio_items.map(({ id: cId, title: cTitle }: IPortfolioItemsOptions) => ({
          label: cTitle,
          value: cId,
        })),
      )
    }
  }, [queryData])

  // MUTATION
  const [createTeamMember, { error: mutationError, data: mutationData }] = useMutation(CREATE_TEAM_MEMBER_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.createTeamMember) {
      const { createTeamMember: createTeamMemberData } = mutationData
      window.location.href = `team-member/${createTeamMemberData.slug}/${createTeamMemberData.locale}`
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Successfully created!' })
    }
  }, [mutationData])

  // POPUPS
  const onClickCreate = () => {
    const initialPageData = {
      locale: DEFAULT_LOCALE,
      status: DEFAULT_STATUS,
    }

    popupCtx.show({
      id: 'create-team-member',
      type: FORM_POPUP,
      options: {
        schema: TEAM_MEMBER_CREATE_SCHEMA,
        data: initialPageData,
        variables: {
          STATUS_OPTIONS,
          SPECTRAL_LOCALE_OPTIONS,
        },
      },
      // eslint-disable-next-line
      submit: (cbdata: any) => createTeamMember({ variables: { data: cbdata } }),
    })
  }
  //  COMPONENT LOGIC
  const [pushQuery] = usePushQuery(location, history)

  if (queryError) return <div>error</div>
  const { teamMemberCount, teamMembers: qTeamMembers = [] } =
    queryData || ({} as { teamMemberCount: number; teamMembers: ITeamMember[] })
  const teamMembers: ITeamMember[] = qTeamMembers as ITeamMember[]

  return (
    <div>
      <PageHeader title="Team Members" withBackground />
      <FlexItem margin="-75px 45px 25px 25px">
        <Grid gap="20px">
          <SectionWrapper>
            <SectionHeader dark={dark}>Actions</SectionHeader>
            <Grid mdCol="1fr auto" gap="20px" alignContent="end" justifyContent="start" alignItems="end">
              <FilterSearch
                name="search"
                label="Search (in name and slug)"
                value={query.search}
                onChange={(value) => pushQuery('search', value)}
              />
              <Button ghost onClick={onClickCreate}>
                Create New Team Member
              </Button>
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Filter</SectionHeader>
            <Grid mdCol={3} gap="20px">
              <FilterDropdown
                name="locale"
                label="Locale"
                value={query.locale ? { value: query.locale, label: query.locale } : undefined}
                options={SPECTRAL_LOCALE_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('locale', value)}
              />
              <FilterDropdown
                name="orderBy"
                label="Order By"
                value={query.orderBy ? { value: query.orderBy, label: query.orderBy } : undefined}
                options={TEAM_MEMBERS_ORDER_BY_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('orderBy', value)}
              />
              <FilterDropdown
                name="status"
                label="Status"
                value={query.status ? { value: query.status, label: query.status } : undefined}
                options={STATUS_OPTIONS as [IOption]}
                onChange={(value) => pushQuery('status', value)}
              />
            </Grid>
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeader dark={dark}>Results</SectionHeader>
            <Grid>
              <Table>
                <Thead>
                  <Th>Name</Th>
                  <Th>Role</Th>
                  <Th>Slug</Th>
                  <Th>Created At</Th>
                  <Th>Status</Th>
                  <Th>Locale</Th>
                  <Th>id</Th>
                </Thead>
                <Tbody>
                  {loading && <p>Loading...</p>}
                  {!!teamMembers.length &&
                    teamMembers.map(({ id, name, role, locale, slug, status, created_at: cAt }) => {
                      return (
                        <Tr key={id}>
                          <Td>
                            <NavLink to={`/team-member/${slug}/${locale}`}>{name}</NavLink>
                          </Td>
                          <Td>{role}</Td>
                          <Td>{slug}</Td>
                          <Td>{dayjs(cAt).format('YYYY.MM.DD HH:mm:ss')}</Td>
                          <Td>{status}</Td>
                          <Td>{SPECTRAL_LOCALE_OPTIONS.find(({ value }) => value === locale)?.label}</Td>
                          <Td>{id}</Td>
                        </Tr>
                      )
                    })}
                </Tbody>
              </Table>
            </Grid>
            <Pagination totalCount={teamMemberCount} pushQuery={pushQuery} query={query} />
          </SectionWrapper>
        </Grid>
      </FlexItem>
    </div>
  )
}

export default TeamMemberListPage
