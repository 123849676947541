import rem from '../helpers/rem.helper'

// COLORS
export const colors = {
  body: 'hsla(0, 0%, 21%, 1)',

  bodyLight: '#ffffff',

  white: '#fff',
  lightGrey: 'hsla(0, 0%, 98%, 1)',
  lightMidGrey: 'hsla(0, 0%, 70%, 1)',
  midGrey: 'hsla(0, 0%, 47%, 1)',
  darkMidGrey: 'hsla(0, 0%, 29%, 1)',
  darkGrey: '#2f2e2e',
  almostBlack: '#1f1e1e',
  black: '#141414',

  main: 'rgba(241,155,57,1)',
  success: 'hsla(117, 86%, 43%, 1)',
  error: 'hsla(0, 56%, 59%, 1)',
  info: 'hsla(204, 100%, 58%, 1)',
  focus: 'hsla(204, 100%, 58%, 1)',
}

// FONTS
export const fonts = {
  anton: 'anton, sans-serif',
  osc: 'montserrat, sans-serif',
}

export const fontSizes = {
  h1: { xxs: rem(48), md: rem(48) },
  h2: { xxs: rem(32), md: rem(42) },
  h3: { xxs: rem(32), md: rem(32) },
  h4: { xxs: rem(22), md: rem(36) },
  h5: { xxs: rem(22), md: rem(25) },
  h6: { xxs: rem(18), md: rem(28) },
  p: { xxs: rem(12), md: rem(13) },

  small: { xxs: rem(14), md: rem(14) },
  medium: { xxs: rem(16), md: rem(15) },
  large: { xxs: rem(20), md: rem(24) },

  xs: { xxs: rem(12), md: rem(14) },
  s: { xxs: rem(12), md: rem(13) },
  md: { xxs: rem(16), md: rem(16) },
  l: { xxs: rem(18), md: rem(18) },
  xl: { xxs: rem(22), md: rem(22) },
  xxl: { xxs: rem(32), md: rem(32) },
  xxxl: { xxs: rem(42), md: rem(64) },
}

// TRANSITIONS

export const transitions = {
  ExtraFast: '.15s',
  Fast: '.3s',
}

// SPACING SIZES

export const space = {
  small: '2.5rem',
  medium: '5rem',
  large: '6.875rem',
}

export const fileTypesIcons: any = {
  pdf: 'insert_drive_file',
  psd: 'insert_drive_file',
  ai: 'insert_drive_file',
  eps: 'insert_drive_file',
  doc: 'insert_drive_file',
  docx: 'insert_drive_file',
  mp4: 'movie',
  mov: 'movie',
  jpg: 'image',
  jpeg: 'image',
  gif: 'image',
  svg: 'image',
  png: 'image',
}
