/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const BLOG_POST_QUERY = gql`
  query BlogPost($locale: Locale!, $slug: String!) {
    blogPost(where: { locale: $locale, slug: $slug }) {
      id
      locale
      status
      title
      slug
      author
      featured_image
      thumbnail_image
      abstract
      category {
        name
        id
        locale
        status
      }
      seo {
        title
        description
        keywords
        image
      }
      modules {
        id
        type
        info
        data
      }
    }

    blogCategories(where: { status: ACTIVE }) {
      id
      name
    }

    products(where: { status: ACTIVE }) {
      id
      name
    }
  }
`

export const BLOG_POSTS_QUERY = gql`
  query BlogPosts(
    $locale: Locale
    $search: String
    $orderBy: BlogPostOrderByInput
    $skip: Int
    $limit: Int
    $status: Status
  ) {
    blogPosts(
      where: { locale: $locale, status: $status }
      searchInTitleAndSlugAndAbstract: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      title
      id
      locale
      status
      slug
      author
      category {
        name
        id
      }
      updated_at
      created_at
    }

    blogPostCount(where: { locale: $locale, status: $status }, searchInTitleAndSlugAndAbstract: $search)

    blogCategories(where: { status: ACTIVE }) {
      id
      name
    }
  }
`
