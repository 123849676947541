import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useParams } from 'react-router'
import { PAGE_QUERY } from '../../../graphql/page/queries/page'
import { dataFormatter } from '../../../helpers/data-formatter'
import FormProvider from '../../../form2/form.provider'
import { PAGE_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { IPage } from '../../../interfaces/page/page.interface'
import { UPDATE_PAGE_MUTATION, DELETE_PAGE_MUTATION } from '../../../graphql/page/mutations/page'
import { STATUS_OPTIONS, LOCALE_OPTIONS } from '../../../config/select-options.config'
import { PageHeader, FlexItem } from '../../../layout'
import { NotificationContext } from '../../../contexts/notification.context'
import { IOption } from '../../../interfaces/schema/schema.interface'

const PageDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { locale: qLocale, id: qId } = useParams() as { locale: string; id: string }
  const capsLocale = qLocale.toUpperCase()
  const [pageID, setPageID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IPage>()
  const [formOptions, setFormOptions] = useState<IOption[]>()

  // QUERY
  const { error: queryError, data: queryData } = useQuery(PAGE_QUERY, {
    variables: { locale: capsLocale, id: qId },
  })
  useEffect(() => {
    if (queryData && queryData.page) {
      const { page } = queryData
      const fd = dataFormatter(page, { jsonFields: ['info', 'data'] })
      const { id, ...formattedData } = fd
      setPageID(id)
      setSnapshot(formattedData as IPage)

      // Forms
      if (queryData.forms && queryData.forms.length) {
        setFormOptions(
          queryData.forms.map(({ id: cId, alias: cAlias }: { id: string; alias: string }) => ({
            label: cAlias,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  // MUTATIONS
  const [updatePage, { error: mutationError, data: mutationData }] = useMutation(UPDATE_PAGE_MUTATION)
  const [deletePage, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(DELETE_PAGE_MUTATION)
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updatePage) {
      const { updatePage: updatePageData } = mutationData
      const fd = dataFormatter(updatePageData, { jsonFields: ['info', 'data'] })
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Page Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting page!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Page Deleted!' })
      setTimeout(() => {
        window.location.href = `/pages`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  // ONEVENT FUNCTIONS
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updatePage({ variables: { id: pageID, data: newData } })
  }

  const onDelete = () => {
    deletePage({ variables: { id: pageID } })
  }

  // COMPONENT LOGIC
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { page } = queryData
  const { locale, title, id, slug } = page as IPage

  return (
    <div>
      <PageHeader title={`Page Details - ${title} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onSubmit={onUpdate}
            onDelete={onDelete}
            deleteVerifier={slug}
            schema={PAGE_UPDATE_SCHEMA}
            variables={{
              STATUS_OPTIONS,
              LOCALE_OPTIONS,
              FORM_OPTIONS: formOptions || [],
            }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default PageDetailsPage
