/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const TEAM_MEMBER_QUERY = gql`
  query TeamMember($locale: Locale!, $slug: String!) {
    teamMember(where: { locale: $locale, slug: $slug }) {
      position
      locale
      status
      phone
      email
      role
      slug
      name
      id
      portfolio_items {
        created_at
        updated_at
        locale
        status
        title
        slug
        date
        id
      }
    }
  }
`

export const TEAM_MEMBERS_QUERY = gql`
  query teamMembers(
    $locale: Locale
    $status: Status
    $search: String
    $orderBy: TeamMemberOrderByInput
    $skip: Int
    $limit: Int
  ) {
    teamMembers(
      where: { locale: $locale, status: $status }
      searchInNameAndSlugAndEmail: $search
      orderBy: $orderBy
      skip: $skip
      limit: $limit
    ) {
      created_at
      updated_at
      position
      locale
      status
      role
      slug
      phone
      email
      name
      id
      portfolio_items {
        created_at
        updated_at
        locale
        status
        title
        slug
        date
        id
      }
    }

    teamMemberCount(where: { locale: $locale, status: $status }, searchInNameAndSlugAndEmail: $search)
  }
`
