/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_BLOG_CATEGORY_MUTATION = gql`
  mutation CreateBlogCategory($data: BlogCategoryCreateInput!) {
    createBlogCategory(data: $data) {
      id
      locale
      status
      name
      slug
    }
  }
`

export const UPDATE_BLOG_CATEGORY_MUTATION = gql`
  mutation UpdateBlogCategory($id: ObjectID!, $data: BlogCategoryUpdateInput!) {
    updateBlogCategory(where: { id: $id }, data: $data) {
      id
      locale
      status
      name
      slug
    }
  }
`

export const DELETE_BLOG_CATEGORY_MUTATION = gql`
  mutation DeleteBlogCategory($id: ObjectID!) {
    deleteBlogCategory(where: { id: $id }) {
      id
    }
  }
`
