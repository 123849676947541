import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { useParams } from 'react-router'
import { dataFormatter } from '../../../helpers/data-formatter'
import FormProvider from '../../../form2/form.provider'
import { BLOG_POST_UPDATE_SCHEMA } from '../../../schemas/schema.map'
import { STATUS_OPTIONS, LOCALE_OPTIONS } from '../../../config/select-options.config'
import { PageHeader, FlexItem } from '../../../layout'
import { NotificationContext } from '../../../contexts/notification.context'
import { IOption } from '../../../interfaces/schema/schema.interface'
import { BLOG_POST_QUERY } from '../../../graphql/blog-post/queries/blog-post'
import { IBlogPost } from '../../../interfaces/blog-post/blog-post.interface'
import { UPDATE_BLOG_POST_MUTATION, DELETE_BLOG_POST_MUTATION } from '../../../graphql/blog-post/mutations/blog-post'

const BlogPostDetailsPage: React.FC = () => {
  const notificationCtx = useContext(NotificationContext)
  const { locale: qLocale, slug: qSlug } = useParams() as { locale: string; slug: string }
  const capsLocale = qLocale.toUpperCase()
  const [blogPostID, setBlogPostID] = useState<string>('')
  const [snapShot, setSnapshot] = useState<IBlogPost>()
  const [blogCategoriesOptions, setBlogCategoriesOptions] = useState<IOption[]>([])
  const [productsOptions, setProductsOptions] = useState<IOption[]>()

  // QUERY
  const { error: queryError, data: queryData } = useQuery(BLOG_POST_QUERY, {
    variables: { locale: capsLocale, slug: qSlug },
  })
  useEffect(() => {
    if (queryData && queryData.blogPost) {
      const { blogPost } = queryData
      const fd = dataFormatter(blogPost, {
        populatedFields: ['category'],
        jsonFields: ['info', 'data'],
      })
      const { id, ...formattedData } = fd
      setBlogPostID(id)
      setSnapshot(formattedData as IBlogPost)

      // Blog Categories
      if (queryData.blogCategories && queryData.blogCategories.length) {
        setBlogCategoriesOptions(
          queryData.blogCategories.map(({ id: cId, name: cName }: { id: string; name: string }) => ({
            label: cName,
            value: cId,
          })),
        )
      }

      // Products
      if (queryData.products && queryData.products.length) {
        setProductsOptions(
          queryData.products.map(({ id: cId, name: cName }: { id: string; name: string }) => ({
            label: cName,
            value: cId,
          })),
        )
      }
    }
  }, [queryData])

  // MUTATIONS
  const [updateBlogPost, { error: mutationError, data: mutationData }] = useMutation(UPDATE_BLOG_POST_MUTATION)
  const [deleteBlogPost, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(
    DELETE_BLOG_POST_MUTATION,
  )
  useEffect(() => {
    if (!mutationError && mutationData && mutationData.updateBlogPost) {
      const { updateBlogPost: updateBlogPostData } = mutationData
      const fd = dataFormatter(updateBlogPostData, {
        populatedFields: ['category'],
        jsonFields: ['info', 'data'],
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _, ...formattedData } = fd
      setSnapshot(formattedData)
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Blog Post Updated!' })
    }
  }, [mutationData])

  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting blog post!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Blog Post Deleted!' })
      setTimeout(() => {
        window.location.href = `/blog-posts`
      }, 1500)
    }
  }, [mutationDeleteData, mutationDeleteError])

  // ONEVENT FUNCTIONS
  // eslint-disable-next-line
  const onUpdate = (data: any) => {
    const newData = { ...snapShot, ...data }
    updateBlogPost({ variables: { id: blogPostID, data: newData } })
  }

  const onDelete = () => {
    deleteBlogPost({ variables: { id: blogPostID } })
  }

  // COMPONENT LOGIC
  if (queryError) return <div>Error happened.</div>
  if (!snapShot) return null

  const { blogPost } = queryData
  const { locale, title, slug } = blogPost as IBlogPost

  return (
    <div>
      <PageHeader title={`Blog Post Details - ${title} [${locale}]`} />
      {!!snapShot && (
        <FlexItem margin="-75px 25px 155px 25px">
          <FormProvider
            onSubmit={onUpdate}
            onDelete={onDelete}
            deleteVerifier={slug}
            schema={BLOG_POST_UPDATE_SCHEMA}
            variables={{
              STATUS_OPTIONS,
              LOCALE_OPTIONS,
              BLOG_CATEGORY_OPTIONS: blogCategoriesOptions,
              PRODUCT_OPTIONS: productsOptions || [],
            }}
            data={snapShot}
          />
        </FlexItem>
      )}
    </div>
  )
}

export default BlogPostDetailsPage
