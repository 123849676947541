/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from 'react'
import { ButtonWrapper, ActionCenter as ActionCenterWrapper } from '../../layout/section/section.styles'
import { Button } from '../../layout'
import { DarkModeContext } from '../../contexts/darkmode.context'
import PopupContext from '../../contexts/popup.context'
import { DELETE_POPUP } from '../popup/popup.map'

export interface IActionCenter {
  isModified: boolean
  onCancel: any
  onDelete?: any
  bottom?: boolean
  top?: boolean
  comparedValue?: string
  popup?: boolean
}

const ActionCenter = ({ isModified, onCancel, onDelete, top, bottom, comparedValue, popup }: IActionCenter) => {
  const darkModeCtx = useContext(DarkModeContext)
  const popupCtx = useContext(PopupContext)
  const { dark } = darkModeCtx

  return (
    <ActionCenterWrapper top={top} bottom={bottom} popup={popup}>
      <ButtonWrapper buttons={onDelete ? '3' : '2'}>
        <Button
          disabled={!isModified}
          secondaryLight={dark}
          secondary={!dark}
          type="button"
          aria-hidden
          ghost
          onClick={(e: any) => {
            e.preventDefault()
            onCancel()
          }}
        >
          Cancel
        </Button>
        {onDelete && (
          <Button
            warning
            type="button"
            onClick={(e: any) => {
              e.preventDefault()
              popupCtx.show({
                id: 'delete_item',
                type: DELETE_POPUP,
                options: {
                  message: 'Are you sure you want to delete?',
                  comparedValue,
                },
                submit: () => onDelete(),
              })
            }}
          >
            Delete
          </Button>
        )}
        <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true" />
        <Button type="submit" disabled={!isModified}>
          Save
        </Button>
      </ButtonWrapper>
    </ActionCenterWrapper>
  )
}
export default ActionCenter
