/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { isEqual } from 'lodash'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import { DatePickerWrapper, HiddenInput, Label } from './date.styles'
import DarkModeContext from '../../../contexts/darkmode.context'
import { Grid, Text, FlexItem } from '../../../layout'
import { IInputProps } from '../../form.provider'
import DateButton from './datebutton.component'

const InputField: React.FC<IInputProps> = (props) => {
  const inputRef = useRef(null)
  const { setValue, value, fieldInfo, moduleInput } = props
  const { name, config } = fieldInfo
  const { flex, alternative } = config
  const { required, label, validationError, disabled = false } = config
  const DarkModeCtx = useContext(DarkModeContext)
  const { dark } = DarkModeCtx

  // CREATE DATE OBJ
  const dateObj = new Date(value || '2019-08-01T12:00:00Z')
  // GET TIMEZONE OFFSET
  const offset = dateObj.getTimezoneOffset() / 60
  // GET THE DATE WITHOUT TIMEZONE OFFSET
  useEffect(() => {
    dateObj.setHours(dateObj.getHours() + offset)
  }, [])
  const [snapshot, setSnapshot] = useState<any>(dateObj)
  // ON SNAPSHOT CHANGE IF WE HAVE VALUE CREATE CORRECTED DATE BY ADDING BACK OFFSET
  // AND SETTING THE RESULT AS VALUE
  useEffect(() => {
    if (snapshot !== undefined && !isEqual(snapshot, dateObj)) {
      if (snapshot === '*%' || null) {
        setValue({ [name as string]: null })
      } else {
        const correctedDate = new Date(snapshot)
        correctedDate.setHours(snapshot.getHours() - offset)
        setValue({ [name as string]: correctedDate.toISOString() })
      }
    }
  }, [snapshot])

  const onChange = (e: any) => {
    setSnapshot(e)
  }
  return (
    <FlexItem flex={flex || '100%'} margin="0 20px 10px 0">
      <Grid alignItems="start" alignContent="start">
        {!moduleInput && label && (
          <Grid alignItems="center" justifyItems="start" mdCol="auto 1fr">
            <Label dark={dark} alternative={alternative}>
              {label}
            </Label>
            {validationError && <Text error>{validationError}</Text>}
          </Grid>
        )}
        <DatePickerWrapper dark={dark} alternative={alternative}>
          <DatePicker
            selected={snapshot}
            required={required}
            onChange={onChange}
            disabled={disabled}
            ref={inputRef}
            customInput={
              <DateButton
                alternative={alternative}
                disabled={disabled}
                onClick={onChange}
                value={snapshot}
                dark={dark}
              />
            }
          />
          {required && <HiddenInput required={required} autoComplete="off" tabIndex={-1} value={value} />}
        </DatePickerWrapper>
      </Grid>
    </FlexItem>
  )
}

export default InputField
