/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_PAGE_MUTATION = gql`
  mutation CreatePage($data: PageCreateInput!) {
    createPage(data: $data) {
      id
      locale
      status
      alias
      title
      slug
    }
  }
`

export const UPDATE_PAGE_MUTATION = gql`
  mutation UpdatePage($id: ObjectID!, $data: PageUpdateInput!) {
    updatePage(where: { id: $id }, data: $data) {
      id
      locale
      status
      alias
      title
      slug
      seo {
        title
        description
        keywords
        image
      }
      modules {
        id
        type
        info
        data
      }
    }
  }
`

export const CLONE_PAGE_MUTATION = gql`
  mutation ClonePage($id: ObjectID!, $tag: String) {
    clonePage(where: { id: $id }, tag: $tag) {
      id
      title
      slug
    }
  }
`

export const DELETE_PAGE_MUTATION = gql`
  mutation DeletePage($id: ObjectID!) {
    deletePage(where: { id: $id }) {
      id
    }
  }
`
