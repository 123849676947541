/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useLazyQuery, useMutation } from 'react-apollo'
import { FileRejection, DropEvent, useDropzone } from 'react-dropzone'
import PopupContext from '../../../contexts/popup.context'
import NotificationContext from '../../../contexts/notification.context'
import { IMedia } from '../../../interfaces/media/media.interface'
import { MEDIAS_QUERY, MEDIA_SIGNED_URL_QUERY } from '../../../graphql/media/queries/media'
import { DELETE_MEDIA_MUTATION, CREATE_MEDIA_FOLDER_MUTATION } from '../../../graphql/media/mutations/media'
import { MEDIA_LIBRARY_MIME_WHITELIST } from '../../../config/variables.config'
import { Grid, Button } from '../../../layout'
import Icon from '../../../layout/icons/icons.styles'
import {
  BreadCrumbWrapper,
  BreadCrumbItem,
  Folder,
  CreateNewFolderInput,
  MediaListItem,
  DeleteFolderItem,
  UploadMediaItem,
  Loading,
} from './media-list.styles'
import { MEDIA_DETAILS_POPUP } from '../../popup/popup.map'
import { fileTypesIcons } from '../../../layout/variables'

/**
 * TODOS
 * - popup details
 * - limit
 */

export interface IMediaListProps {
  onSelect: (selectedURL: string) => void
  colItems?: number
  withDetailsPopup?: boolean
}

const MediaList: React.FC<IMediaListProps> = ({ colItems, onSelect, withDetailsPopup = true }) => {
  const popupCtx = useContext(PopupContext)
  const notificationCtx = useContext(NotificationContext)
  const [mediaList, setMediaList] = useState<[IMedia]>()
  const [signedURL, setSignedURL] = useState<string>()
  const [file, setFile] = useState<File>()
  const [actualFolder, setActualFolder] = useState<string>('/')
  const [newFolderName, setNewFolderName] = useState<string>('')
  const [selectedMedium, setSelectedMedium] = useState<string>('')

  /**
   * GET MEDIA LIST BY FOLDERS QUERY
   */
  const [getMedias, { error: queryError, data: queryData, loading }] = useLazyQuery(MEDIAS_QUERY)
  useEffect(() => {
    if (queryData && queryData.medias) {
      const { medias } = queryData as { medias: [IMedia] }
      setMediaList(medias)
    }
  }, [queryData])

  useEffect(() => {
    getMedias({ variables: { folder: actualFolder.replace('spectral/media/', '') } })
  }, [actualFolder])

  /**
   * DELETE MEDIUM MUTATION
   */
  const [deleteMedia, { error: mutationDeleteError, data: mutationDeleteData }] = useMutation(DELETE_MEDIA_MUTATION)
  useEffect(() => {
    if (mutationDeleteError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while deleting media!' })
    }
    if (mutationDeleteData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Media Deleted!' })
      getMedias({ variables: { folder: actualFolder.replace('spectral/media/', '') } })
      setActualFolder('/')
    }
  }, [mutationDeleteData, mutationDeleteError])

  /**
   * CREATE MEDIA FOLDER MUTATION
   */
  const [createMediaFolder, { error: createMediaFolderError, data: createMediaFolderData }] = useMutation(
    CREATE_MEDIA_FOLDER_MUTATION,
  )
  useEffect(() => {
    if (createMediaFolderError) {
      notificationCtx.show({ type: 'error', icon: 'cancel', message: 'Error while createing media folder!' })
    }
    if (createMediaFolderData) {
      notificationCtx.show({ type: 'success', icon: 'ok', message: 'Folder created!' })
      setNewFolderName('')
      getMedias({ variables: { folder: actualFolder.replace('spectral/media/', '') } })
    }
  }, [createMediaFolderData, createMediaFolderError])

  /**
   * UPLOAD MEDIA
   */
  const [getMediaSignedURL, { data: mediaSignedURLData }] = useLazyQuery(MEDIA_SIGNED_URL_QUERY)
  useEffect(() => {
    if (mediaSignedURLData && mediaSignedURLData.mediaSignedURL) {
      setSignedURL(mediaSignedURLData.mediaSignedURL.url)
    }
  }, [mediaSignedURLData])

  const onClickUpload = () => {
    if (file) {
      const folderPrefix = actualFolder.replace('spectral/media/', '')
      if (folderPrefix && folderPrefix !== '/') {
        Object.defineProperty(file, 'name', {
          writable: true,
          value: folderPrefix + file.name,
        })
      }

      // Step 1. - get signed url
      const { name } = file
      const splittedName = name?.split('.')
      const fileExtension = splittedName[splittedName.length - 1]
      const fileName = splittedName.slice(0, -1).join('.')
      getMediaSignedURL({ variables: { fileExtension, fileName } })
    }
  }

  const uploadToSignedURL = async (uploadUrl: string, uploadFile: File) => {
    const response = await fetch(uploadUrl, {
      method: 'PUT',
      body: uploadFile,
      headers: {
        // important! => otherwise the uploaded file is private.
        'X-Amz-Acl': 'public-read',
      },
    })
    await response.text()
  }

  useEffect(() => {
    if (signedURL && file) {
      // Step 2. - upload file to url
      uploadToSignedURL(signedURL, file)
      setFile(undefined)
      // Step 3. - refresh the items
      setTimeout(() => {
        getMedias({ variables: { folder: actualFolder.replace('spectral/media/', '') } })
      }, 500)
    }
  }, [signedURL])

  /**
   * DROPZONE FOR DRAG N DROP
   */
  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length) {
      setFile(acceptedFiles[0])
    }
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1, accept: MEDIA_LIBRARY_MIME_WHITELIST })

  /**
   * COMPONENT LOGIC
   */
  if (queryError) return <div>Error happened.</div>

  const onClickDelete = (key: string) => {
    deleteMedia({ variables: { key } })
  }

  return (
    <div>
      <BreadCrumbWrapper>
        {actualFolder
          ?.split('/')
          .filter(Boolean)
          .map((c, i) => {
            const index = i
            if (c === 'spectral') return null
            if (c === 'media')
              return (
                <BreadCrumbItem key={index} onClick={() => setActualFolder('/')} role="none">
                  root <span>/</span>
                </BreadCrumbItem>
              )

            const folderLink = actualFolder?.split('/').filter(Boolean).slice(0, i).join('/')
            if (folderLink === 'spectral/media') return null

            return (
              <BreadCrumbItem key={index} onClick={() => setActualFolder(`${folderLink}/`)} role="none">
                {folderLink?.split('/').filter(Boolean)[folderLink?.split('/').filter(Boolean).length - 1]}{' '}
                <span>/</span>
              </BreadCrumbItem>
            )
          })}
        <BreadCrumbItem active>
          {actualFolder?.split('/').filter(Boolean)[actualFolder?.split('/').filter(Boolean).length - 1] || 'root'}
        </BreadCrumbItem>
        <BreadCrumbItem>
          <span>/</span>
        </BreadCrumbItem>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            const prefix = actualFolder.replace('spectral/media/', '')
            let realName = newFolderName
            if (prefix || prefix !== '/') realName = prefix + newFolderName
            createMediaFolder({ variables: { folder: realName } })
          }}
        >
          <CreateNewFolderInput
            pattern="^[a-zA-Z0-9-]+$"
            type="text"
            value={newFolderName}
            onChange={(e) => {
              setNewFolderName(e.target.value)
            }}
          />
          <Button ghost type="submit" disabled={!newFolderName}>
            Create new folder
          </Button>
        </form>
      </BreadCrumbWrapper>

      {loading && (
        <Loading>
          <div className="spinner">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </Loading>
      )}
      {!loading && (
        <Grid mdCol={colItems || 8} gap="15px" marginBottom="35px">
          <UploadMediaItem>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag and drop files here, or click to select files</p>
            </div>
            {!!file && <p>Your selected file: {file.name}</p>}
            <Button secondary ghost disabled={!file} type="button" onClick={onClickUpload}>
              Upload
            </Button>
          </UploadMediaItem>

          {!mediaList?.length && actualFolder !== '/' && (
            <DeleteFolderItem
              onClick={() => {
                onClickDelete(actualFolder)
              }}
            >
              <Icon>delete</Icon>
              <div>
                <p>Delete empty folder</p>
              </div>
            </DeleteFolderItem>
          )}

          {!!mediaList?.length &&
            mediaList.map((medium) => {
              const splittedKey = medium?.key?.split('.')

              // Folder
              if (splittedKey?.length < 2) {
                return (
                  <Folder key={medium?.key} onClick={() => setActualFolder(medium?.key)} role="none">
                    <Icon size={100}>folder</Icon>
                    <div>
                      <p>
                        {medium?.key?.split('/').filter(Boolean)[medium?.key?.split('/').filter(Boolean).length - 1]}
                      </p>
                    </div>
                  </Folder>
                )
              }

              const fileExtension = medium?.key?.split('.')?.pop()?.toLowerCase()

              // MediaItem
              return (
                <MediaListItem
                  acive={selectedMedium === medium?.key}
                  key={medium?.key}
                  background={
                    fileTypesIcons[fileExtension || ''] === 'image' ? medium?.url.replace(/ /gi, '%20') : undefined
                  }
                  onClick={() => {
                    setSelectedMedium(medium?.key)
                    onSelect(medium?.url)
                    if (withDetailsPopup) {
                      popupCtx.show({
                        id: medium?.key,
                        type: MEDIA_DETAILS_POPUP,
                        options: {
                          data: {
                            onDelete: (key: string) => {
                              deleteMedia({ variables: { key } })
                            },
                            medium,
                          },
                        },
                        submit: () => null,
                      })
                    }
                  }}
                >
                  {fileTypesIcons[fileExtension || ''] !== 'image' && (
                    <Icon size={50}>{fileTypesIcons[fileExtension || '']}</Icon>
                  )}
                  <div>
                    <p>{medium?.key?.split('/').filter(Boolean)[medium?.key?.split('/').filter(Boolean).length - 1]}</p>
                  </div>
                </MediaListItem>
              )
            })}
        </Grid>
      )}
    </div>
  )
}

export default MediaList
