import styled from 'styled-components/macro'
import rem from '../../../helpers'
import { colors, fonts } from '../../../layout'
import { INPUT_BORDER_RADIUS } from '../../../config/layout.config'

interface IInputStyles {
  moduleInput?: boolean
  alternative?: boolean
  disabled?: boolean
  dark?: boolean
}

export const Label = styled('label')<{ alternative?: boolean; dark?: boolean }>`
  margin: 0px 0px 0px 0.1rem;
  color: ${colors.darkGrey};
  font-family: ${fonts.osc};
  font-weight: 200;
  font-size: 14px;
  ${({ alternative, dark }) =>
    alternative &&
    !dark &&
    `
    color: ${colors.white};
  `}

  ${({ alternative, dark }) =>
    alternative &&
    dark &&
    `
    color: ${colors.white};
  `}
`

export const Grab = styled.span`
  background-color: transparent;
  position: absolute;
  display: block;
  height: 100%;
  cursor: grab;
  z-index: 33;
  width: 50%;
  left: 0;
  top: 0;
`

export const DatePickerWrapper = styled('div')<IInputStyles>`
  position: relative;
  z-index: 20;
  & > .react-datepicker-wrapper {
    width: 100%;
    height: 37px;
  }
  & input {
  }
  & div.react-datepicker__month-container {
    font-family: ${fonts.osc};
  }
  & div.react-datepicker__header {
    border-bottom: 1px solid ${colors.main};
  }
  & div.react-datepicker__triangle {
    left: 150px !important;
  }
  & div.react-datepicker__day--selected,
  div.react-datepicker__day--keyboard-selected {
    background-color: ${colors.main};
  }
`

export const HiddenInput = styled.input`
  position: absolute !important;
  opacity: 0 !important;
  padding: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: -1;
`

export const CustomInput = styled('button')<{ dark?: boolean; alternative?: boolean; disabled?: boolean }>`
  padding: ${rem(12)} ${rem(10)} ${rem(12)} ${rem(25)};
  border: 1px solid ${colors.lightMidGrey};
  border-radius: ${INPUT_BORDER_RADIUS};
  font-family: ${fonts.osc};
  background-color: white;
  height: 100% !important;
  width: 100% !important;
  box-sizing: border-box;
  font-size: 0.85rem;
  text-align: left;
  transition: 1s;
  outline: none;
  height: 37px;
  width: 100%;
  z-index: 20;
  ${({ disabled }) =>
    disabled &&
    `
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      cursor: not-allowed;
      user-select: none;
      color: #b7b7b7;
      opacity: .6;
    `}
  ${({ alternative, dark }) =>
    alternative &&
    !dark &&
    `
    color: ${colors.white};
    background-color: ${colors.lightMidGrey};
    border: 1px solid ${colors.lightMidGrey};
    box-shadow: none;
  `}

    ${({ alternative, dark }) =>
    alternative &&
    dark &&
    `
    color: ${colors.white};
    background-color: ${colors.darkMidGrey};
    border: 1px solid ${colors.darkMidGrey};
    box-shadow: none;
  `}
`
