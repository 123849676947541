/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react'
import { InputStyles, Label } from '../../form2/fields/input/input.styles'
import { Button, Grid } from '../../layout'

interface IFilterSearch {
  name: string
  label?: string
  value?: string
  onChange: (s: string) => void
}

const FilterSearch: React.FC<IFilterSearch> = props => {
  const { value, onChange, name, label } = props
  const [search, setSearch] = useState('')
  useEffect(() => {
    setSearch(props.value as string)
  }, [value])

  const handleKeydown = (e: any) => {
    if (e.key === 'Enter') {
      setSearch(e.target.value)
      onChange(e.target.value)
    }
  }

  return (
    <Grid mdCol="400px auto auto" alignItems="end" justifyContent="start">
      <Grid>
        {!!label && <Label>{label}</Label>}
        <InputStyles
          name={name}
          onChange={(e: any) => setSearch(e.target.value)}
          value={search}
          onKeyDown={handleKeydown}
        />
      </Grid>
      <Button onClick={() => onChange(search)}>Search</Button>
      <Button
        onClick={() => {
          setSearch('')
          onChange('')
        }}
        secondary
      >
        Reset
      </Button>
    </Grid>
  )
}

export default FilterSearch
