/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_BLOG_POST_MUTATION = gql`
  mutation CreateBlogPost($data: BlogPostCreateInput!) {
    createBlogPost(data: $data) {
      id
      locale
      status
      author
      title
      slug
    }
  }
`

export const UPDATE_BLOG_POST_MUTATION = gql`
  mutation UpdateBlogPost($id: ObjectID!, $data: BlogPostUpdateInput!) {
    updateBlogPost(where: { id: $id }, data: $data) {
      id
      locale
      status
      title
      slug
      author
      featured_image
      thumbnail_image
      abstract
      category {
        name
        id
        locale
        status
      }
      seo {
        title
        description
        keywords
        image
      }
      modules {
        id
        type
        info
        data
      }
    }
  }
`

export const DELETE_BLOG_POST_MUTATION = gql`
  mutation DeleteBlogPost($id: ObjectID!) {
    deleteBlogPost(where: { id: $id }) {
      id
    }
  }
`
