/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useLazyQuery } from 'react-apollo'
import {
  Backdrop,
  PopupWrapper,
  CloseButton,
  UploadTypeSelector,
  UploadTypeSelectorItem,
  UploadTypeBody,
  UploadUrlInput,
  UrlWrapper,
} from './media.styles'
import { MEDIA_POPUP } from '../../popup.map'
import { Button, Grid } from '../../../../layout'
import Icon from '../../../../layout/icons/icons.styles'
import { IMedia } from '../../../../interfaces/media/media.interface'
import { MEDIAS_QUERY } from '../../../../graphql/media/queries/media'
import MediaList from '../../../media/media-list'

const MediaPopup: React.FC<any> = (props) => {
  const [activeUploadType, setActiveUploadType] = useState<string>('url')
  const [mediaList, setMediaList] = useState<[IMedia]>()

  /**
   * QUERY
   */
  const [getMedias, { data: queryData, loading }] = useLazyQuery(MEDIAS_QUERY)
  useEffect(() => {
    if (queryData && queryData.medias) {
      const { medias } = queryData as { medias: [IMedia] }
      if (medias?.length) setMediaList(medias)
    }
  }, [queryData])

  const { id, type, submit, hide, options } = props
  const { data } = options
  const { actualURL } = data
  const [selectedURL, setSelectedURL] = useState<string>(actualURL)
  if (type !== MEDIA_POPUP) return <div>Invalid popup type!</div>

  const onConfirm = () => {
    submit(selectedURL)
    hide(id)
  }

  const onClose = () => {
    hide(id)
  }

  return (
    <Backdrop>
      <form
        action=""
        onSubmit={(e) => {
          e.preventDefault()
          onConfirm()
        }}
      >
        <PopupWrapper>
          <CloseButton onClick={onClose} type="button">
            <Icon>close</Icon>
          </CloseButton>
          <Grid alignItems="top" justifyContent="start" mdCol="auto auto" gap="30px">
            <UploadTypeSelector>
              <UploadTypeSelectorItem active={activeUploadType === 'url'} onClick={() => setActiveUploadType('url')}>
                URL
              </UploadTypeSelectorItem>
              <UploadTypeSelectorItem
                active={activeUploadType === 'select'}
                onClick={() => {
                  if (!mediaList) getMedias()
                  setActiveUploadType('select')
                }}
              >
                Select
              </UploadTypeSelectorItem>
            </UploadTypeSelector>
            <UploadTypeBody>
              {activeUploadType === 'url' && (
                <UrlWrapper>
                  <p>URL</p>
                  <UploadUrlInput type="url" value={selectedURL} onChange={(e) => setSelectedURL(e.target.value)} />
                  <div>
                    <a href={selectedURL} target="_blank" rel="noreferrer">
                      <Icon size={22} aria-hidden="true">
                        image
                      </Icon>
                      Open Image in new tab
                    </a>
                    <a href="https://cloudinary.com/users/login" target="_blank" rel="noreferrer">
                      <Icon size={22} aria-hidden="true">
                        wallpaper
                      </Icon>
                      Open Cloudinary
                    </a>
                  </div>
                </UrlWrapper>
              )}
              {activeUploadType === 'select' && (
                <MediaList colItems={4} withDetailsPopup={false} onSelect={setSelectedURL} />
              )}
            </UploadTypeBody>
          </Grid>
          <Grid gap="50px" alignItems="center">
            <Grid alignItems="center" justifyContent="center" mdCol="auto auto" gap="30px">
              <Button secondary ghost onClick={() => hide(id)}>
                Cancel
              </Button>
              <Button type="submit">Confirm</Button>
            </Grid>
          </Grid>
        </PopupWrapper>
      </form>
    </Backdrop>
  )
}

export default MediaPopup
