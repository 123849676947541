/* eslint-disable import/prefer-default-export */

import { gql } from 'apollo-boost'

export const CREATE_TEAM_MEMBER_MUTATION = gql`
  mutation CreateTeamMember($data: TeamMemberCreateInput!) {
    createTeamMember(data: $data) {
      position
      locale
      status
      email
      phone
      name
      role
      slug
      id
    }
  }
`

export const UPDATE_TEAM_MEMBER_MUTATION = gql`
  mutation UpdateTeamMember($id: ObjectID!, $data: TeamMemberUpdateInput!) {
    updateTeamMember(where: { id: $id }, data: $data) {
      position
      locale
      status
      phone
      email
      role
      name
      id
      portfolio_items {
        locale
        status
        title
        slug
        date
        id
      }
    }
  }
`

export const DELETE_TEAM_MEMBER_MUTATION = gql`
  mutation DeleteTeamMember($id: ObjectID!) {
    deleteTeamMember(where: { id: $id }) {
      id
    }
  }
`
