import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import { NotificationWrapper } from './notification.styles'
import { INotificationItem } from './notification-provider'
import { CloseButton } from '../popup/types/form/popup.styles'
import { Text, Grid } from '../../layout'
import Icon from '../../layout/icons/icons.styles'
import rem from '../../helpers/rem.helper'

const Notification: React.FC<{
  notification: INotificationItem
  hide: (id: string) => void
}> = (props) => {
  const { notification, hide } = props
  const { id, message, type } = notification
  const [show, setshow] = useState(true)

  useEffect(() => {
    if (show === true) {
      setTimeout(() => {
        setshow(false)
      }, 7000)
    }
  }, [show])

  const transitions = useTransition(show, null, {
    from: {
      transform: 'translateY(-100px)',
      position: 'relative',
      borderRadius: '5px',
      overflow: 'hidden',
      minHeight: '50px',
      width: '700px',
      top: '-100px',
      opacity: 0,
      right: 0,
    },
    enter: { opacity: 1, transform: 'translateY(100px)' },
    leave: { opacity: 0, transform: 'translateY(-100px)' },
    config: config.wobbly,
  })

  return (
    <>
      {transitions.map(
        ({ item, key, props: animationprops }) =>
          item && (
            <animated.div key={key} style={animationprops}>
              <NotificationWrapper type={type || 'info'} key={id}>
                <Grid xsCol="auto 1fr" padding={rem(20)}>
                  <Icon>{type === 'success' ? 'done' : type}</Icon>
                  <Text>{message}</Text>
                </Grid>
                <CloseButton
                  onClick={() => {
                    setshow(false)
                    setTimeout(() => {
                      hide(id as string)
                    }, 300)
                  }}
                  type="button"
                >
                  X
                </CloseButton>
              </NotificationWrapper>
            </animated.div>
          ),
      )}
    </>
  )
}

export default Notification
